import React from 'react'
import profit from '../images/icon.png'
import loss from '../images/icon-1.png'

const Transactions = () => {
  return (
    <div className='container '>
      <div className='row'>
        <div className="col-lg-12 mt-3 p-1">
          <div className='mt-2 ms-0 h6Transactions'>In-App Transactions</div>
          <div className="mt-2 ms-3 rounded-lg overflow-hidden">
            <table responsive className='table transactionTable'>
              <tbody>
                <tr className=''>
                  <td><img src={profit} className='mt-2' width={40} alt='' /></td>
                  <td className='p-3 transactionId'><label>Bessi Cooper</label><br />
                    <span>W-9868956432</span>
                  </td>
                  
                  <td className='p-3 transactionStat'>
                    <span className='transactionValue1'>-UGX 250</span><br/>
                    <span className='transactionTime'>Jul 4 2024, 21:42</span>
                  </td>
                </tr>
 
                <tr>
                  <td><img src={profit} width={40} className='mt-2' alt='' /></td>
                  <td className='p-3 transactionId'><label>Buy Ethereum</label><br />
                    <span>W-9868956432</span>
                  </td>

                  <td className='p-3 transactionStat'>
                    <span className='transactionValue2'>-UGX 1000</span><br/>
                    <span className='transactionTime'>Jul 2 2024, 16:20</span>
                  </td>
                 </tr>

                 <tr>
                   <td><img src={loss} width={40} className='mt-2' alt='' /></td>
                   <td className='p-3 transactionId'><label>Sell Bitcoin</label><br />
                    <span>W-9868956432</span>
                  </td>
                  
                  <td className='p-3 transactionStat'>
                    <span className='transactionValue3'>+UGX 3500</span><br/>
                    <span className='transactionTime'>Jul 1 2024, 8:42</span>
                  </td>
                  <br/>
                  <br/>
                 </tr>
                 <br/>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Transactions