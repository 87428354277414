import React, { useState,useEffect,useRef } from "react";
import Navbars from "../../../navbar";
import { APIProvider, Map, Marker, useMap, } from '@vis.gl/react-google-maps';
import axios from 'axios';
import back from '../../../images/arrow-left-01.png';
import locationcurrent from '../../../images/address-icons/Group 1488.png';
import { GoogleMapurl,getuserlocationapi} from "../../../api/helper/urlhelper";
import { useIpContext } from "../../../api/hooks/iphook";
import PlaceAutocomplete from "../../addresssection/autocomplete";
import { useNavigate } from "react-router-dom";
import { extractLoginUser ,handleApiError} from "../../../api/utils/utils";
import Updatesearchlocationstore from "./updatesearchlocationstore";
import Updatesearchlocationconfirm from "./updatesearchlocationconfirm";
import Updatesearchedlocation from "./updatesearchedlocation";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../api/utils/axiosutils";
import WithToast from "../../../api/hooks/withtoasthook";
import { useCountryData } from "../../../api/hooks/mobilehook";
import Mapdesign from "../newaddress/mapdesign";
import { fetchAddress } from "../../addresscommon/addressfunction";
const googlemapkey = GoogleMapurl();
const getuserlocationapiurl = getuserlocationapi();
const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    }

    marker.setPosition(place.geometry?.location);
  }, [map, place, marker]);

  return null;
};
function Updateaddress({showErrorToast,login_user}){
  const navigate = useNavigate();
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryByIp,loadCountryBySelection,empty_values,
  } = useCountryData();
  const [addressline_1, setAddressline_1] = useState([]);
    const {dynamic} = useParams();
    useEffect(() => {
      const getuseraddress = async () => {
        try {
          const response = await axiosInstance.get(getuserlocationapiurl);
          if (response.status === 200) {
            const emergency = response.data.data;
            const matchingEntry = emergency.find(entry => entry.id.toString() === dynamic.toString());
            if (matchingEntry) {
              setAddressline_1(matchingEntry);
            } else {
              navigate(`/u/${login_user}/profile`);
            }
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        }
      };
  
      getuseraddress();
    }, [dynamic, login_user, navigate, showErrorToast]);
    const [addressComponents, setAddressComponents] = useState({
      street: "",
      country: "",
      city: "",
      zipcode: "",
      state: "",
      latitude: "",
      longitude: "",
      code:""
    });
    const countryData = {
      mccId,
      dialCode,
      countryCode,
      countryOptions,
      mobile_length,
      mobile_starting_digits,
      isLoading,
      loadCountryByIp,
      setMccId,
      setDialCode,
      setCountryCode,
      setMobile_length,
      loadCountryBySelection,
      setMobile_starting_digits,
      empty_values
    };
    const [mobileChange, setMobileChange] = useState('');
    useEffect(() => {
      setAddressComponents({
        street: addressline_1.address_line_1 ? addressline_1.address_line_1 : '',
        country: addressline_1.country && addressline_1.country.name ? addressline_1.country.name : '',
        city: addressline_1.city && addressline_1.city.name ? addressline_1.city.name : '',
        zipcode: addressline_1.postal_code ? addressline_1.postal_code : '',
        state: addressline_1.state && addressline_1.state.name ? addressline_1.state.name : '',
        latitude: addressline_1.latitude ? addressline_1.latitude : '',
        longitude: addressline_1.longitude ? addressline_1.longitude : '',
        code:addressline_1.country && addressline_1.country.country_code ? addressline_1.country.country_code : '' 
      });
      setFormValues({
        Name: addressline_1.first_name ? addressline_1.first_name : '',
        id: addressline_1.id,
        dialcode: '',
        code: addressline_1.mcc && addressline_1.mcc.country_code ? addressline_1.mcc.country_code : '',
        houseNumber: addressline_1.block_number ? addressline_1.block_number : '',
        apartment: addressline_1.apartment_number ? addressline_1.apartment_number : '',
        addressLine2: addressline_1.address_line_2 ? addressline_1.address_line_2 : '',
        location_type: addressline_1.location_type ? addressline_1.location_type : '',
        addressType: addressline_1.location_type ? addressline_1.location_type : '',
        otherType: addressline_1.location_type ? addressline_1.location_type : '',
        default: addressline_1.is_primary ? true : false,
        city: '',
        postcode: '',
        fetchcondition:(addressline_1?.mcc && addressline_1?.mcc.country_code && addressline_1?.mobile  ) ? true : false
      });
      setMobileChange(addressline_1.mobile ? addressline_1.mobile : '');
      setMccId(addressline_1.mcc && addressline_1.mcc.id ? addressline_1.mcc.id : '');
      setDialCode('');
      setCountryCode(addressline_1?.mcc?.country_code);
    }, [addressline_1]);
    
    const [formValues, setFormValues] = useState({
        Name:'',
        id:addressline_1.id,
        dialcode:'',
        code:  '',
        houseNumber: '',
        apartment:  '',
        addressLine2: '',
        location_type: '',
        addressType: '',
        otherType: '',
        default:false, 
        city:'',
        postcode:'',
        fetchcondition:false
      });
      const { getUserIpCountryCode,latitude,longitude} = useIpContext();
    const [locationState, setLocationState] = useState({ searchLocation: false, searchedLocation: false,searchedLocationconfirm :false,searchedLocationstore:true });
  const [cameraProps, setCameraProps] = useState({center: { lat:123.456, lng: 789.012 },zoom: 15});
  const [loading, setLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [error,setError] = useState('');
  const [markerPosition, setMarkerPosition] = useState({lat:parseFloat(addressline_1.latitude),lng:parseFloat(addressline_1.longitude)});
  useEffect(() => {
    if (addressline_1.latitude && addressline_1.longitude) {
      setCameraProps({
        center: { lat: parseFloat(addressline_1.latitude), lng: parseFloat(addressline_1.longitude) },
        zoom: 15
      });
      setMarkerPosition({lat:parseFloat(addressline_1.latitude),lng:parseFloat(addressline_1.longitude)})
    }
  }, [addressline_1]);
  useEffect(() => {
    // if(!formValues.fetchcondition){
    if (latitude && longitude) {
      setCameraProps({
        center: { lat: latitude, lng: longitude },
        zoom: 10
      });
    }
  // }
  }, [latitude, longitude]);
  const fetchLocation = async () => {
    setLoading(true);
      await getUserIpCountryCode().finally(()=>setLoading(false));
  };
  const handleMarkerClick = (event) => {
    if (event) {
      const clickedLatLng = {
        lat: event.detail.latLng.lat,
        lng: event.detail.latLng.lng
      };
      setCameraProps({ center: clickedLatLng, zoom: 15 });
      setMarkerPosition(clickedLatLng);
      fetchAddress(clickedLatLng,setAddressComponents,setLocationState,setCameraProps,setMarkerPosition);
    }
  };

  // const fetchAddress = async (latLng) => {
  //   try {
  //     const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
  //       params: {
  //         latlng: `${latLng.lat},${latLng.lng}`,
  //         key: googlemapkey
  //       }
  //     });

  //     if (response.data.results.length > 0) {
  //       const addressData = response.data.results[0];
  //       const components = addressData.address_components;

  //       let street = "";
  //       let country = "";
  //       let city = "";
  //       let zipcode = "";
  //       let state = "";
  //       let code = "";
  //       components.forEach(component => {
  //         if (component.types.includes("route")) {
  //           street = component.long_name;
  //         }
  //         if (component.types.includes("country")) {
  //           country = component.long_name;
  //           code = component.short_name;
  //         }
  //         if (component.types.includes("locality")) {
  //           city = component.long_name;
  //         }
  //         if (component.types.includes("postal_code")) {
  //           zipcode = component.long_name;
  //         }
  //         if (component.types.includes("administrative_area_level_1")) {
  //           state = component.long_name;
  //         }
  //       });

  //       setAddressComponents({
  //         street,
  //         country,
  //         city,
  //         zipcode,
  //         state,
  //         latitude: latLng.lat,
  //         longitude: latLng.lng,
  //         code
  //       });
  //       setLocationState((prevstate) => ({
  //         ...prevstate,
  //         searchLocation: false,
  //         searchedLocation: true
  //       }));
  //     } 
  //   } catch (error) {
  //     console.error("Error fetching geocode address:", error);
  //   }
  // };

  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setCameraProps({ center: currentLatLng, zoom: 15 });
        // setLocationState((prevstate) => ({
        //   ...prevstate,
        //   searchLocation: false,
        //   searchedLocation: true
        // }));
        setMarkerPosition(currentLatLng);
        // fetchAddress(currentLatLng);
        fetchAddress(currentLatLng,setAddressComponents,setLocationState,setCameraProps,setMarkerPosition);
      }, (error) => {
        console.error("Error getting current location:", error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const showstate = (show) => {
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation:'searchedLocation' === show,
      searchLocation:'searchLocation' === show,
      searchedLocationconfirm: 'searchedLocationconfirm' === show,
      searchedLocationstore: 'searchedLocationstore' === show,
    }));
  }
  const handleCameraChange = (ev) => {
    setCameraProps(ev.detail);
  };

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    setCameraProps({ center: place.geometry.location, zoom: 15 });
    setMarkerPosition(place.geometry.location);
    const newlat = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }
    fetchAddress(newlat,setAddressComponents,setLocationState,setCameraProps,setMarkerPosition);
  };
  const backprofile = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const confirmAndProceed = (show) => {
    showstate(show);
    // setFormValues((prevstate)=>({
    //   ...prevstate,fetchcondition:true,addressType:'',otherType:''
    // }))
    // setLocationState(prevstate => ({
    //     ...prevstate,
    //     searchedLocation: false,
    //     searchLocation: false,
    //     searchedLocationconfirm: true,
    //     searchedLocationstore: false
    //   }));
  }
  const confirmAndProceed1 = (show) => {
    showstate(show);
  }
  const emptynull = () => {
    setMarkerPosition(null);
    setAddressComponents((prevstate)=>({
      ...prevstate,street:'', country: "",city: "",zipcode: "",state: "",latitude: "",longitude: ""
    }));

  }
  const Change = async() =>{
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation: false,
      searchLocation:true,
      searchedLocationconfirm: false,
      searchedLocationstore:false,
    }));
    // setFormValues((prev)=>({
    //   ...prev,
    //   fetchcondition:false
    // }))
    await fetchLocation();
    // setMobileChange('');
    // setMccId('');
    // setCountryCode('');
    // setFormValues((prevstate)=>({
    //   ...prevstate,
      // Name:'',
      // houseNumber: '',
      // apartment: '',
      // addressLine2: '',
      // addressType: '',
      // otherType: '',
      // default:false,
      // city:'',
      // postcode:'',
      // fetchcondition:true,
      // countryoptions:[],
    // }));
    setAddressComponents((prevstate)=>({
      ...prevstate,
      // street: "",
      // country: "",
      // city: "",
      // zipcode: "",
      // state: "",
      latitude: "",
      longitude: "",
      // code:""
    }));
    setMarkerPosition(null);
  }
  const back1 = async (show) => {
    emptynull();
    await fetchLocation();
    showstate(show)
  };
  const back2 = (show) => {
    showstate(show);
  }
  const back3 = (show) => {
    showstate(show);
  }
    const [divHeight, setDivHeight] = useState(window.innerHeight - 160); // Initial height minus navbar height
  
    useEffect(() => {
      const updateDivHeight = () => {
        setDivHeight(window.innerHeight - 160); // Subtract navbar height
      };
  
      // Add event listener for window resize
      window.addEventListener("resize", updateDivHeight);
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener("resize", updateDivHeight);
      };
    }, []);
    return(
        <>
        <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars/>
        </div>
        <div className="address_div">
          <div className="address_div1" style={{height:divHeight}}>
          <div className="address_divhalf1">
          {locationState.searchLocation && (
              <>

                <div className="d-flex align-items-center">
                  <img src={back} onClick={backprofile} style={{cursor:'pointer'}} className="me-2" alt="back" width="17px" height="14px" />
                  <h6 className="addaddressh6">Add Address</h6>
                </div>
                <div>
                  <p className="addaddressSearchp">Search Location</p>
                  <button onClick={handleUseCurrentLocation} className="addaddresscurrentbutton">
                    <img src={locationcurrent} className="locationcurrent" alt="upload" />
                    Use my current location
                  </button>
                  <PlaceAutocomplete onPlaceSelect={handlePlaceSelect} />
                </div>
                </>
                )}
                  {locationState.searchedLocation && (
            <Updatesearchedlocation
              addressComponents={addressComponents}
              cameraProps={cameraProps}
              markerPosition={markerPosition}
              selectedPlace={selectedPlace}
              googlemapkey={googlemapkey}
              onCameraChanged={handleCameraChange}
              confirmAndProceed={confirmAndProceed}
              back1={back1}
              Change={Change}
            />
           )}
           {locationState.searchedLocationconfirm && (
            <Updatesearchlocationconfirm
            addressComponents={addressComponents}
            cameraProps={cameraProps}
            back2={back2}
            markerPosition={markerPosition}
            selectedPlace={selectedPlace}
            googlemapkey={googlemapkey}
            onCameraChanged={handleCameraChange}
            confirmAndProceed1={confirmAndProceed1}
            handleCameraChange={handleCameraChange}
            formValues={formValues}
            setFormValues={setFormValues}
            Change={Change}
            error={error}
            setError={setError}
            countryData={countryData}
                setMobileChange={setMobileChange}
                mobileChange={mobileChange}
            />
           )}
           {locationState.searchedLocationstore && (
            <Updatesearchlocationstore
            addressComponents={addressComponents}
                back3={back3}
                formValues={formValues}
                setFormValues={setFormValues}
                Change={Change}
                countryData={countryData}
                mobileChange={mobileChange}
                login_user={login_user}
            />
           )}
                           </div>
              <div className="address_divhalf2">
              {loading ? (
                    <div className="skeleton-loader1"></div>
                  ) : (
                    <Mapdesign handleMarkerClick={locationState.searchLocation ? handleMarkerClick : ''} selectedPlace={selectedPlace}  markerPosition={markerPosition} cameraProps={cameraProps} handleCameraChange={handleCameraChange}/>
                    // <APIProvider apiKey={googlemapkey} solutionChannel="GMP_devsite_samples_v3_rgmbasicmap">
                    //   <Map {...cameraProps} onCameraChanged={handleCameraChange} gestureHandling="greedy" onClick={handleMarkerClick}>
                    //     {markerPosition && <Marker position={markerPosition} onClick={handleMarkerClick} ref={markerRef} />}
                    //   </Map>
                    //   <MapHandler place={selectedPlace} marker={markerRef.current} />
                    // </APIProvider>
                  )}
            </div>
            </div>
          </div>
          </div>
        
        </>
    );
}
export default WithToast(Updateaddress);