import React,{useState,useRef,useEffect} from "react";
import { useNavigate } from "react-router-dom";
// import file from "../images/job_details_icons/Document_green-01.png";
import eye from "../images/job_details_icons/eye_green-01.png";
// import cancel from '../images/add_document/cancel-02.png';
import plus1 from '../images/plus.png';
// import transparent from '../images/11-01.jpg';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { DatePicker } from "antd";
import acdamichistoryimg from '../images/Acadamic-01.png';
import adddocument from '../images/document_add-01.png';
import acdamicdeleteimg from '../images/trash-red-circle-01.png';
import closecircle from '../images/times-circle-01.png';
import axiosInstance from "../api/utils/axiosutils";
// import { UseToast } from "../api/common/toastcontext";
import Swal from 'sweetalert2';
import fileopen from '../images/folder-open-01.png';
import dayjs from 'dayjs';
import edit from '../images/job_details_icons/edit_1-01.png';
import { Image } from "antd";
import popdeletes from '../images/general-id/delete-01.png';
import { formatDateForInput,transformString,handleDragOver,handleKeyDown,CustomSuffixIcon,handleEditfilechange,handleFileChange,formatDateForExpiry,formatFileSize,parseFileExtension,handleDrop,handleDrop1,handleDragOver1,customStyles1,extractLoginUser,disableFutureDates,handleApiError, disablePastDates } from "../api/utils/utils";
import { acdamichistorydeleteapi,acdamiclevelapi,acdamicstoreapi,acdamichistoryupdateapi,acdamichistorygetapi,publicDomain } from "../api/helper/urlhelper";
import SkeletonLoader from "./skeletonloader";
import WithToast from "../api/hooks/withtoasthook";
const acdamiclevelapiurl = acdamiclevelapi();
const acdamicstoreapiurl = acdamicstoreapi();
const acdamichistorygetapiurl = acdamichistorygetapi();
const acdamichistoryupdateapiurl = acdamichistoryupdateapi();
const acdamichistorydeleteapiurl = acdamichistorydeleteapi();
const publicUrl = publicDomain();
function Acdamichistory({documentRef,showErrorToast}){
    const navigate = useNavigate();
    const login_user = extractLoginUser();
    const [showupdate4, setShowupdate4] = useState(false);
    const [show4, setShow4] = useState(false);
    const [isLoading4,setIsLoading4] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [schoolNameupdate, setSchoolNameupdate] = useState('');
    const [academicLevelupdate, setAcademicLevelupdate] = useState(null);
    const [startacdamicDateupdate, setStartacdamicDateupdate] = useState('');
    const [endacdamicDateupdate, setEndacdamicDateupdate] = useState('');
    const [schoolName, setSchoolName] = useState('');
    const [academicLevel, setAcademicLevel] = useState(null);
    const [startacdamicDate, setStartacdamicDate] = useState('');
    const [endacdamicDate, setEndacdamicDate] = useState('');
    const [career, setCareer] = useState('');
    const [achievement, setAchievement] = useState('');
    const [careerupdate, setCareerupdate] = useState('');
    const [achievementupdate, setAchievementupdate] = useState('');
    const [acdamicid, setAcdamicid] = useState('');
    const [levels, setLevels] = useState([]);
    const [acdamichistorys, setAcdamichistorys] = useState([]);
    const [selectededitacdamicimage, setSelectededitacdamicimage] = useState(null);
    const [selectededitacdamicimageupdate, setSelectededitacdamicimageupdate] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressColor, setProgressColor] = useState("#28a745");
    const [editImage, setEditImage] = useState([]);
    const [editImagedetails, setEditImageDetails] = useState(false);
    const [image, setImage] = useState([]);
    const [fileSize, setFileSize] = useState(0);
    const [error422,setError422] = useState('');
    const [levelloading,setLevelloading] = useState(false);
    const [fileType, setFileType] = useState('');
    const [format, setFormat] = useState('');
    const [docname, setDocname] = useState('');
    const [editProgress, setEditProgress] = useState(100);
    const [editProgressColor, setEditProgressColor] = useState('#28a745');
    const [editDocName, setEditDocName] = useState('');
    const [editFileSize, setEditFileSize] = useState(editImage.fileSize);
    const [isPreviewVisible, setPreviewVisible] = useState(false);
    const [isPreviewVisible1, setPreviewVisible1] = useState(false);
    const [expiryerror,setExpiryerror] = useState({
      expirydateerror : '',editexpirydateerror : '',editimageerror : '',imageerror:''
    });
    const acceptPDF = false;
    const [isOpen, setIsOpen] = useState(false);
  
    const handleOpenChange = (open) => {
      setIsOpen(open);
    }; 
    const handleEditError = (message) => {
      setExpiryerror((prevState)=>({
        ... prevState,
        editimageerror : message
      }))
  }
  const handleError = (message) => {
    setExpiryerror((prevState)=>({
      ... prevState,
      imageerror : message
    }))
  }
    const handleExpiryClick = (e) => {
      if (!startacdamicDate) {
        setExpiryerror((prevState) => ({
          ...prevState,
          expirydateerror:'Please select Date From first'
        }));
        setIsOpen(false);
      } else {
        setExpiryerror((prevState) => ({
          ...prevState,
          expirydateerror:''
        }));
        setIsOpen(true);
      }
    };
    const handleEditExpiryClick = (e) => {
      if (!startacdamicDateupdate) {
        setExpiryerror((prevState) => ({
          ...prevState,
          editexpirydateerror:'Please select Date From first'
        }));
        setIsOpen(false);
      } else {
        setExpiryerror((prevState) => ({
          ...prevState,
          editexpirydateerror:''
        }));
        setIsOpen(true);
      }
    };
    const acdamichistoryget = async () => {
        try {
          setIsLoading1(true);
          const response = await axiosInstance.get(acdamichistorygetapiurl,);
          if (response.status === 200) {
            setIsLoading1(false);
            setAcdamichistorys(response.data.data);
          }
        } catch (error) {
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      }
      useEffect(() => {
        acdamichistoryget();
    }, []);
      const acdamichistorydocument = (acdamic) =>{
        const id = acdamic.id;
        // localStorage.setItem('userId',id);
        navigate(`/u/${login_user}/documents/acdamichistory/${id}/document`);
      }
      const handleacdamiclevelget = async () => {
        try {
          setLevelloading(true);
          const response = await axiosInstance.get(acdamiclevelapiurl);
          if (response.status === 200) {
            const level = response.data.data;
            setLevels(level.map(company => ({ value: company.id, label: company.name })));
            setLevelloading(false);
          }
        } catch (error) {
          setLevelloading(false);
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      }
      const handlelevelchange = (selectedOption) => {
        setAcademicLevel(selectedOption);
        setError422('');
      }
      const handlelevelchangeupdate = (selectedOption) => {
        setAcademicLevelupdate(selectedOption);
        setError422('');
      }
      const initialStateacdamic = {
        academicLevel: '',
        schoolName: '',
        startacdamicDate: '',
        endacdamicDate:'',
        career:'',
        achievement:'',
        uploading: false,
      }
      const handleFileDrop = (selectedFile) => {
        handleFileChange(
          { target: { files: [selectedFile] } },
          setImage,
          setFileSize,
          setFileType,
          setProgress,
          setProgressColor,
          setUploading,
          setFormat,
          setDocname
        );
      };
      const handleeditFileDrop = (selectedFile1) => {
        handleEditfilechange(
          { target: { files: [selectedFile1] } },
          setEditImage,
          setFileSize,
          setFileType,
          setEditProgress,
          setEditProgressColor,
          setEditImageDetails,
          setFormat,
          setEditDocName
        );
      } 
      const handleClose4 = () => {
        setShow4(false);
        setAcademicLevel(initialStateacdamic.academicLevel);
        setSchoolName(initialStateacdamic.schoolName);
        setStartacdamicDate(initialStateacdamic.startacdamicDate);
        setEndacdamicDate(initialStateacdamic.endacdamicDate);
        setImage([]);
        setError422('');
        setExpiryerror((prevState)=>({
          ...prevState,expirydateerror:''
        }));
        setExpiryerror((prevState)=>({
          ...prevState,imageerror:''
        }));
        setCareer(initialStateacdamic.career);
        setAchievement(initialStateacdamic.achievement);
        setUploading(initialStateacdamic.uploading);
      }
      const close4 = () => {
        setShow4(false);
        handleClose4();
      }
      const addacdamichistory = () => {
        handleacdamiclevelget();
        setShowupdate4(false);
        setShow4(true);
      }
      const academicfileInputRef = useRef(null);
      const handleacdamicFileClick = () => {
        academicfileInputRef.current.click();
        setError422('');
        setExpiryerror((prevState)=>({
          ...prevState,imageerror:''
        }))
      }
      const handleacdamicimageeye = (image) => {
        setSelectededitacdamicimage(image);
        setPreviewVisible(true);
        setShow4(false);
      }
      const handleacdamicdelete = () => {
        setUploading(false);
        setProgress(0);
        setImage([]);
      }
      const handleacdamichistorydelete = async (acdamic) => {
        try {
          const id = acdamic.id;
          const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
    
          if (result.isConfirmed) {
            setIsLoading4(true);
            const response = await axiosInstance.post(`${acdamichistorydeleteapiurl}?id=${id}`, {},);
            if (response.status === 200) {
              await acdamichistoryget();
              handleClose4();
              setIsLoading4(false);
            }
          }
        }
        catch (error) {
          handleApiError(error,setIsLoading4,null,true,showErrorToast);
        }
      }
      const [acdamicisformvalid, setAcdamicisformvalid] = useState(false);
        const checkFormacdamicValidity = () => {
        const isValid = (
          schoolName !== '' &&
          startacdamicDate !== null &&
          endacdamicDate !== null &&
          academicLevel !== '' &&
          image.length > 0  
        );
        setAcdamicisformvalid(isValid);
      };
      useEffect(() => {
        checkFormacdamicValidity();
      }, [schoolName,startacdamicDate,endacdamicDate,image]);
      const acdamicapopuplodcancel = () => {
        handleClose4();
      }
      const handleacdamicsave = async () => {
        try {
          setIsLoading4(true);
          let imagestring = image.toString();
          const data = {
            school: schoolName,
            start: formatDateForInput(startacdamicDate),
            end: formatDateForExpiry(endacdamicDate),
            education_level_id: academicLevel.value,
            image: imagestring
          }
          if(career){
            data.field_of_study = career
          }
          if(achievement){
            data.achievement = achievement
          }
          const response = await axiosInstance.post(acdamicstoreapiurl, data,);
          if (response.status === 200) {
            handleClose4();
            await acdamichistoryget()
            setIsLoading4(false);
          }
        } catch (error) {
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
        }
      }
      const updateacdamichistory = async(acdamic) => {
        await handleacdamiclevelget();
        setShow4(false);
        setShowupdate4(true);
        if (acdamic) {
          setAcdamicid(acdamic.id);
          setSchoolNameupdate(acdamic.school);
          setAcademicLevelupdate(acdamic.user_education_level ? {label:acdamic.user_education_level.name,value:acdamic.user_education_level.id}:null);
          const startacdamicdate = dayjs(acdamic.start,'YYYY-MM-DD');
          setStartacdamicDateupdate(startacdamicdate);
          const endacdamicdate = dayjs(acdamic.end,'YYYY-MM-DD');
          setEndacdamicDateupdate(endacdamicdate);
          setCareerupdate(acdamic.field_of_study);
          setAchievementupdate(acdamic.achievement);
          const imageUrl = `${publicUrl}/core/${acdamic.image}`;
          setEditImage(imageUrl);
          const filesize = formatFileSize(imageUrl.filesize);
          setFileSize(filesize)
          const filename = imageUrl.split('/').pop();
          const experienceType = filename.split('-')[1];
          setFormat(parseFileExtension(experienceType));
          setFileType(experienceType.replace(/\.[^/.]+$/, ""));
        }
      }
      const [Isacdamicdelete, setIsacdamicdelete] = useState(false);
      const handleupdateClose4 = () => {
        setShowupdate4(false);
        setCareerupdate('');
        setSchoolNameupdate('');
        setAcademicLevelupdate('');
        setStartacdamicDateupdate('');
        setEndacdamicDateupdate('');
        setError422('');
        setEditImage('');
        setExpiryerror((prevState)=>({
          ...prevState,editexpirydateerror:''
        }));
        setExpiryerror((prevState)=>({
          ...prevState,editimageerror:''
        }));
        setAchievementupdate('');
        setEditImageDetails(false);
        setEditProgress(100);
        setIsacdamicdelete(false);
      }
      const closeupdate4 = () => {
        setShowupdate4(false);
        handleupdateClose4();
      }
      const academicfileInputRef1 = useRef(null);
      const handleacdamicFileClick1 = () => {
        academicfileInputRef1.current.click();
        setError422('');
        setExpiryerror((prevState)=>({
          ...prevState,editimageerror:''
        }))
      }
      const handleacdamicimageupdateeye = (editImage) => {
        setSelectededitacdamicimageupdate(editImage);
        setPreviewVisible1(true);
        setShowupdate4(false);
      }
     
      const handleacdamicupdatedelete = () => {
        setEditImage([]);
        setEditProgress(0);
        setEditFileSize(0);
        setEditImageDetails(true);
        setIsacdamicdelete(true);
      }
      const handleacdamicupdatecancel = () => {
        setShowupdate4(false);
        handleupdateClose4();
      }
      const [acdamiceditisformvalid, setAcdamiceditisformvalid] = useState(false);
      const checkFormacdamicupdateValidity = () => {
        const isValid = (
          schoolNameupdate !== '' &&
          startacdamicDateupdate !== null &&
          endacdamicDateupdate !== null &&
          academicLevelupdate !== '' &&
          editImage.length > 0  
        );
        setAcdamiceditisformvalid(isValid);
      };
      useEffect(() => {
        checkFormacdamicupdateValidity();
      }, [schoolNameupdate,startacdamicDateupdate,endacdamicDateupdate,academicLevelupdate,editImage]);
      const handleacdamicupdatesave = async () => {
        try {
            setIsLoading4(true);
          let data;
          let imagestring = editImage.toString();
          if (Isacdamicdelete) {
            data = {
              id: acdamicid,
              school: schoolNameupdate,
              start: formatDateForInput(startacdamicDateupdate),
              end: formatDateForExpiry(endacdamicDateupdate),
              education_level_id: academicLevelupdate.value,
              image: imagestring
            }
          } else {
            data = {
              id: acdamicid,
              school: schoolNameupdate,
              start: formatDateForInput(startacdamicDateupdate),
              end: formatDateForExpiry(endacdamicDateupdate),
              education_level_id: academicLevelupdate.value,
            }
          }
          if(achievementupdate){
            data.achievement = achievementupdate
          }
          if(careerupdate){
            data.field_of_study = careerupdate
          }
          const response = await axiosInstance.post(acdamichistoryupdateapiurl, data,);
          if (response.status === 200) {
            handleupdateClose4();
           await acdamichistoryget();
            setIsLoading4(false);
          }
        } catch (error) {
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
        }
      }
    return(
        <>
          {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
           {acdamichistorys.length > 0 ? (
                    acdamichistorys.map((acdamic, index) => (
                      <div key={index} className="docbox">
                        <div className="d-flex align-items-center" style={{ width: "100%",gap:'10px' }}>
                          <div className="d-flex align-items-center acdamic45" style={{ width: '45%',overflow:'hidden' }}>
                            <div className="me-3">
                            <img src={`${publicUrl}/core/${acdamic.image}`} alt="car" className="docvehicleimg"></img>
                            </div>
                            <div className="workhistorydiv">
                                <h6 className="workhistoryh6">{acdamic.user_education_level.name}</h6>
                                <p className="workhistoryp">{acdamic.school}</p>
                            </div>
                          </div>
                          <div className="acdamic20" style={{ width: '20%',overflow:'hidden' }}>
                              <div className="text-start">
                                <h6 className="workhistoryh61">{new Date(acdamic.start).getFullYear()}-{new Date(acdamic.end).getFullYear()}</h6>
                              </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-end acdamic35" style={{ width: '35%' }}>
                              <div className="docvechilebuttons me-3">
                                <button style={{ position: 'relative' }} onClick={() => updateacdamichistory(acdamic)} className=" docuploadvehicle">
                                  <div className="butimag">
                                    <div className="__col docvechilespan">
                                      <img src={edit} alt="deposits" className="me-2" width='18px' height='auto' />
                                    </div>
                                    <span className="__col docvechilespan">Update Detail</span>
                                  </div>
                                </button>
                                <button style={{ position: 'relative' }} onClick={() =>acdamichistorydocument(acdamic)} className=" docuploadviewdocument">
                                  <div className="butimag">
                                    <div className="__col docvechilespan">
                                      <img src={adddocument} alt="deposits" className="me-2" width='18px' height='auto' />
                                    </div>
                                    <span className="__col docvechilespan">Document</span>
                                  </div>
                                </button>
                              </div>
                              <div>
                                <img src={acdamicdeleteimg} alt="delete" onClick={() => handleacdamichistorydelete(acdamic)} className="docvehicledeletered"></img>
                              </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex mt-5 justify-content-center align-items-center flex-column generalnew ">
                       {!isLoading1 && (
                        <>
                        <img src={acdamichistoryimg} alt="document" className="docorangeimage"></img>
                        <h6 className="addnewgeneralh6 mt-2">Add Academic Background</h6>
                        <p className="generalp">Tell us about your academic background. Include the Academic Level you’ve earned, the institutions you’ve attended, any notable achievements, and additional certifications or training programs completed during your studies.</p>
                        <button className="addnewgeneralbutton addacdamic300" onClick={addacdamichistory}>Add Academic Background</button>
                        </>
                      )}
                      {isLoading1 && (
                       <> 
                         <SkeletonLoader documentRef={documentRef}/>
                      </>
                      )}
                    </div>
                  )}
                  {acdamichistorys.length > 0 && (
                    <div className="text-end mt-3" style={{ width: '97%' }}>
                      <button className="docaddnew" onClick={addacdamichistory}>
            <img
              src={plus1}
              className="me-2"
              alt="plus"
              width="15px"
              height="auto"
            ></img>
            New
          </button>
                    </div>
                  )}

                  
      <Modal show={show4} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={handleClose4} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Add Academic Background</h6>
            <img src={closecircle} onClick={close4} alt="close" className="docpopclose"></img>
          </div>
          <div>
          <div className="error-message1">{error422}</div>
            <div className="mt-3 mb-2">
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">School / College name<span className="docpopstar">*</span></label>
                  <input className={`form-control docform ${schoolName ? 'docform-active' : ''}`} placeholder="Enter School/College Name"  value={schoolName} onChange={(e) =>{ setSchoolName(e.target.value);setError422('');}} />
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Academic Level<span className="docpopstar">*</span></label>
                  <Select options={levels} isLoading={levelloading}  value={academicLevel}  isSearchable={true} placeholder="Select Work type" onChange={handlelevelchange} styles={customStyles1(Boolean(academicLevel))}  />
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Date From<span className="docpopstar">*</span></label>
                  <DatePicker value={startacdamicDate}  disabledDate={disableFutureDates} onChange={(date) => {setStartacdamicDate(date);setError422('');setEndacdamicDate('');setExpiryerror((prevState) => ({...prevState,expirydateerror:''}))}} picker="month" placeholder="MM/YY" className={`${startacdamicDate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: startacdamicDate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={startacdamicDate}/>}  onKeyDown={handleKeyDown}/>
                  {expiryerror.expirydateerror && <div className="error-message">{expiryerror.expirydateerror}</div>}
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Date To<span className="docpopstar">*</span></label>
                  <DatePicker value={endacdamicDate} onClick={handleExpiryClick}  open={isOpen}  onOpenChange={handleOpenChange} disabledDate={disablePastDates(startacdamicDate)} onChange={(date) => {setEndacdamicDate(date);setError422('');}} picker="month" placeholder="MM/YY" className={`${endacdamicDate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: endacdamicDate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={endacdamicDate}/>}  onKeyDown={handleKeyDown}/>
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Career</label>
                  <input className={`form-control docform ${career ? 'docform-active' : ''}`} placeholder="Enter Career" value={career} onChange={(e) => {setCareer(e.target.value);setError422('');}} />
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Achievement</label>
                  <input className={`form-control docform ${achievement ? 'docform-active' : ''}`} placeholder="Enter Achievement" value={achievement} onChange={(e) => {setAchievement(e.target.value);setError422('');}} />
                </div>
              </div>
            </div>
            {uploading ? (
              <div className="popprogressbox">
                <div className="d-flex align-items-center ">
                  <div className="d-flex align-items-center mb-2" style={{width:'80%'}}>
                    <img src={image} alt="file" className="popuploadfileimg me-3"></img>
                    <div style={{overflow:'hidden'}}>
                      <p className="popfilefrontp">{`${transformString(fileType)}`}.{transformString(format)}</p>
                      <p className="popfilekb">{formatFileSize(fileSize)}</p>
                    </div>
                  </div>
                  <div style={{textAlign:'end',width:'20%'}}>
                    {progress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleacdamicimageeye(image)} className="popupeye me-3"></img>
                    )}
                    <img src={popdeletes} alt="delete" className="popupeye" onClick={handleacdamicdelete}></img>
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            ) : (
              <div className="popinputbox mt-3" onClick={handleacdamicFileClick} onDrop={(e) => handleDrop(e, handleFileDrop,handleError,acceptPDF)} onDragOver={handleDragOver}>
                <div className="d-flex align-items-center justify-content-center flex-column p-4">
                  <img src={fileopen} className="popuploadflle" alt="file" width='60px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                <input type="file" ref={academicfileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, setImage, setFileSize, setFileType, setProgress, setProgressColor, setUploading,setFormat,setDocname)}/>
              </div>
            )}
            {expiryerror.imageerror && <div className="error-message">{expiryerror.imageerror}</div>}
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
              <button className="docpopcancel me-3" onClick={acdamicapopuplodcancel}>Cancel</button>
              <button className="docpopsave"  style={{backgroundColor: acdamicisformvalid ? '#5D64F2' : '#EAEAEA', color: acdamicisformvalid ? '#FFF' : '#333' , cursor: acdamicisformvalid ? 'pointer' : 'not-allowed' }} disabled={!acdamicisformvalid} onClick={handleacdamicsave}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* acdamichistoryupdatepopup */}

      <Modal show={showupdate4} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={handleupdateClose4} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Add Academic Background</h6>
            <img src={closecircle} onClick={closeupdate4} alt="close" className="docpopclose"></img>
          </div>
          <div>
          <div className="error-message1">{error422}</div>
            <div className="mt-3 mb-2">
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">School / College name<span className="docpopstar">*</span></label>
                  <input className={`form-control docform ${schoolNameupdate ? 'docform-active' : ''}`} placeholder="Enter School/College Name" value={schoolNameupdate} onChange={(e) => {setSchoolNameupdate(e.target.value);setError422('');}} />
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Academic Level<span className="docpopstar">*</span></label>
                  <Select options={levels} isLoading={levelloading} value={academicLevelupdate}  isSearchable={true} placeholder="Select Work type" onChange={handlelevelchangeupdate} styles={customStyles1(Boolean(academicLevelupdate))}  />
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Date From<span className="docpopstar">*</span></label>
                  <DatePicker value={startacdamicDateupdate} disabledDate={disableFutureDates}  onChange={(date) => {setStartacdamicDateupdate(date);setError422('');setEndacdamicDateupdate('')}} picker="month" placeholder="MM/YY" className={`${startacdamicDateupdate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: startacdamicDateupdate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={startacdamicDateupdate}/>}  onKeyDown={handleKeyDown}/>
                  {expiryerror.editexpirydateerror && <div className="error-message">{expiryerror.editexpirydateerror}</div>}
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Date To<span className="docpopstar">*</span></label>
                  <DatePicker value={endacdamicDateupdate} onClick={handleEditExpiryClick}  open={isOpen}  onOpenChange={handleOpenChange} disabledDate={disablePastDates(startacdamicDateupdate)} onChange={(date) => {setEndacdamicDateupdate(date);setError422('');}} picker="month" placeholder="MM/YY" className={`${endacdamicDateupdate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: endacdamicDateupdate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={endacdamicDateupdate}/>}  onKeyDown={handleKeyDown}/>
                </div>
              </div>
              <div className="row docpoprow">
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Career</label>
                  <input className={`form-control docform ${careerupdate ? 'docform-active' : ''}`} placeholder="Enter Career" value={careerupdate} onChange={(e) => {setCareerupdate(e.target.value);setError422('');}} />
                </div>
                <div className="col-sm-6 col-12">
                  <label className="docpoplabel1">Achievement</label>
                  <input className={`form-control docform ${achievementupdate ? 'docform-active' : ''}`} placeholder="Enter Achievement" value={achievementupdate} onChange={(e) =>{ setAchievementupdate(e.target.value);setError422('');}} />
                </div>
              </div>
            </div>
            {editImagedetails ? (
              <div className="popinputbox mt-3" onClick={handleacdamicFileClick1} onDrop={(e) => handleDrop1(e, handleeditFileDrop,handleEditError,acceptPDF)}
              onDragOver={handleDragOver1}>
                <div className="d-flex align-items-center justify-content-center flex-column p-4">
                  <img src={fileopen} className="popuploadflle" alt="file" width='60px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                <input type="file" ref={academicfileInputRef1} accept=".jpg, .jpeg, .png" style={{ display: 'none' }} onChange={(e) => handleEditfilechange(e, setEditImage, setFileSize, setFileType, setEditProgress, setEditProgressColor, setEditImageDetails, setFormat, setEditDocName)} />
              </div>
            ) : (
              <div className="popprogressbox">
                <div className="d-flex align-items-center ">
                  <div className="d-flex align-items-center mb-2" style={{width:'80%'}}>
                    <img src={editImage} alt="file" className="popuploadfileimg me-3"></img>
                    <div style={{overflow:'hidden'}}>
                      <p className="popfilefrontp">{`${transformString(fileType)}`}{transformString(format)}</p>
                      {fileSize && fileSize >= 1024 && (
                      <p className="popfilekb">{formatFileSize(fileSize)}</p>
                      )}
                    </div>
                  </div>
                  <div style={{width:'20%',textAlign:'end'}}>
                    {editProgress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleacdamicimageupdateeye(editImage)} className="popupeye me-3"></img>
                    )}
                    <img src={popdeletes} alt="delete" className="popupeye" onClick={handleacdamicupdatedelete}></img>
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${editProgress}%`, backgroundColor: editProgressColor }} aria-valuenow={editProgress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            )}
            {expiryerror.editimageerror && <div className="error-message">{expiryerror.editimageerror}</div>}
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
              <button className="docpopcancel me-3" onClick={handleacdamicupdatecancel}>Cancel</button>
              <button className="docpopsave" style={{backgroundColor: acdamiceditisformvalid ? '#5D64F2' : '#EAEAEA', color: acdamiceditisformvalid ? '#FFF' : '#333' , cursor: acdamiceditisformvalid ? 'pointer' : 'not-allowed' }} disabled={!acdamiceditisformvalid} onClick={handleacdamicupdatesave}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {selectededitacdamicimage &&(
            <Image
            src={selectededitacdamicimage}
            alt="Enlarged Image"
            preview={{
             visible: isPreviewVisible,
             onVisibleChange: setPreviewVisible,
             onClose: () => {
               setPreviewVisible(false);
               setShow4(true); 
             },
           }}
            style={{ maxWidth: '100%', maxHeight: '80vh' ,zIndex:'9999' , display:'none'}}/>
      )}
      {selectededitacdamicimageupdate &&(
         <Image
         src={selectededitacdamicimageupdate}
         alt="Enlarged Image"
         preview={{
          visible: isPreviewVisible1,
          onVisibleChange:setPreviewVisible1,
          onClose: () => {
            setPreviewVisible1(false);
            setShowupdate4(true); 
          },
        }}
         style={{ maxWidth: '100%', maxHeight: '80vh' ,zIndex:'9999' , display:'none'}}/>
      )}
        </>
    );
}
export default WithToast(Acdamichistory);