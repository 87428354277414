import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

const AvgDataUsage = () => {
  return (
      <div className='row'>
        <div className='col mt-4  dataUsageTitle'>
          <h6 className='ms-2'>Average Data Usage</h6><br />
          <br/>
          <div className="d-flex justify-content-center align-items-center chartDiv">
            <CircularProgressbarWithChildren
              value={65}
              strokeWidth={15}
              styles={buildStyles({
                textColor: 'RGB(2, 201, 139)', pathColor: 'RGB(2, 201, 139)',
                trailColor: 'lightgrey', strokeLinecap: 'butt'
              })}>

              <div className='percent'>
                65%
              </div>
              <div className='chartText'>
                Data Usage
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
  );
};

export default AvgDataUsage;
