import React,{useState,useEffect} from 'react';
import Select,{components} from 'react-select';
import useMenuHeight, { countrystyle, handleBeforeInput, selectWidth } from '../api/utils/utils';
const PhoneInputWithCountrySelector = ({countryOptions = [],handleCountryChange,dialCode,mobileChange,handlePhoneNumberChange, handlePaste,isLoading = false,minheight,walletprofileinput,walletprofileinputactive,countryCode,id='mobilenumber',newclassname='',placeholder='Enter your Mobile number'}) => {
    
  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={data.flag}
        alt=""
        style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
    </div>
  );

  const customOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={props.data.flag}
          alt=""
          style={{ width: '25px', height: 'auto', marginRight: '10px' }}
        />
        {props.data.label}
      </div>
    </components.Option>
  );
  const [selwidth, setSelWidth] = useState(selectWidth(dialCode));
  const menuHeight = useMenuHeight(200, 400);
  const [inputHeight, setInputHeight] = useState(40); 
  useEffect(() => {
    const input = document.querySelector('input[name="responsephoneNumber"]');

    const updateHeight = () => {
      if (input) {
        const computedStyle = window.getComputedStyle(input);
        setInputHeight(parseInt(computedStyle.height, 10));
      }
    };

    // Initial update
    updateHeight();

    // ResizeObserver to monitor changes
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    if (input) {
      resizeObserver.observe(input);
    }

    // Cleanup observer
    return () => {
      if (input) resizeObserver.unobserve(input);
    };
  }, [mobileChange, walletprofileinput]);
  useEffect(() => {
    setSelWidth(selectWidth(dialCode));
  }, [dialCode]);
  const handleKeyPress = (e) => {
    handleBeforeInput(e);
  };
  return (
      <>
      <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
        <Select options={countryOptions} onChange={handleCountryChange} value={countryOptions.find((option) => option.value === countryCode)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={isLoading}
          styles={countrystyle('82px',menuHeight,inputHeight)}
        />
      </div>
      <div
        style={{
          height: '30px',
          width: '2px',
          backgroundColor: '#ccc',
          margin: '0 8px',
          position: 'absolute',
          left: `${selwidth}px`,
          top: `calc(${inputHeight / 2}px)`,
          transform: 'translateY(-50%)',
        }}
      ></div>
      <p style={{position: 'absolute',left: '80px',top:`calc(${inputHeight / 2}px)`,transform: 'translateY(-50%)',cursor: 'pointer',color: 'hsl(0, 0%, 20%)',fontSize: 'clamp(12px, 1.5vw, 14px)',lineHeight: '1.5', marginBottom: '0px',fontWeight: '400',}}> {dialCode}</p>
      <input type="text" id={id} name="responsephoneNumber" onPaste={handlePaste} value={mobileChange} onBeforeInput={handleKeyPress} onChange={(e) => handlePhoneNumberChange(e.target.value)} className={`form-control  ${newclassname} ${walletprofileinput} ${ mobileChange ? walletprofileinputactive : '' }`} style={{ paddingLeft: `${selwidth + 18}px` }} placeholder={placeholder}/>
      </>
  );
};

export default PhoneInputWithCountrySelector;
