import React from 'react'

function Loader() {
  return (
    <>
    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
    </>
  )
}

export default Loader;