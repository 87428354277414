import React,{useState} from "react";
import back from '../../../images/arrow-left-01.png';
import searchedlocationicon from '../../../images/address-icons/Group 1858.png';
import WithToast from "../../../api/hooks/withtoasthook";
import { locationupdate } from "../../addresscommon/addressfunction";
import UseCustomNavigate from "../../../api/hooks/customnavigatehook";
import Loader from "../../../component/loader";
function Updatesearchlocationstore({addressComponents,back3,formValues,setFormValues,showErrorToast,login_user,Change,countryData,mobileChange,}){
    const {mccId,empty_values} = countryData;
    const [isLoading4,setIsLoading4]=useState(false);
    const [error422,setError422]=useState('');
    const navigate = UseCustomNavigate();
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: type === 'checkbox' ? checked : value
        }));
      };
      const location_string = formValues.addressType === 'Others' ? formValues.otherType : formValues.addressType;  
      const { street, city, state, country, zipcode ,latitude,longitude,code} = addressComponents;
      const confirmAndProceed2 = async() => {
        let data = {
          id:formValues.id,
          block_number:formValues.houseNumber,
          address_line_1:street,
          address_line_2:formValues.addressLine2,
          postal_code:zipcode || formValues.postalcode,
          city:city || formValues.city,
          state:state,
          country:country,
          location_type:location_string,
          is_primary:formValues.default ? 1 : 0,
          latitude:latitude,
          longitude:longitude,
          first_name:formValues.Name,
          mobile:mobileChange,
          mcc_id:mccId,
          country_code:code,
         }
         if(addressComponents.street){
          data.address_line_1=street
         }
         if(formValues.apartment){
          data.apartment_number=formValues.apartment
         }
         const filteredData = Object.fromEntries(
          Object.entries(data).filter(([key, value]) => value !== "" && value !== null)
        );
        const after_successcall = () => {
          empty_values();
        }
        await locationupdate(filteredData,login_user,setIsLoading4,setError422,showErrorToast,navigate,after_successcall);
      }
    return(
        <>
         {isLoading4 && (<Loader/>)} 
        <div>
          <div className="d-flex align-items-center">
            <img src={back} onClick={()=>back3('searchedLocationconfirm')} style={{cursor:'pointer'}} className="me-2" alt="back" width="17px" height="14px" />
            <h6 className="addaddressh6">Add Address</h6>
          </div>
          <div>
            <p className="addaddressSearchp">Confirm your address</p>
            <div className='combinedaddressdiv mb-3'>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="combinedaddressdivflex w-100">
                  <img src={searchedlocationicon} className='searchedlocationimage' alt='searchlocate' ></img>
                  <div>
                    <p className="confirmaddressp confirmaddressp1">{location_string}</p>
                    <p className="confirmaddressp2">{`${formValues.houseNumber ? formValues.houseNumber : ''}`}</p>
                    <p className="confirmaddressp2">{formValues.addressLine2}</p>
                    {(city || state) &&<p className="confirmaddressp2">{`${city}${state ? ` - ${state}` : ''}`},</p>}
                    {(country || zipcode) && <p className="confirmaddressp2">{`${country}${zipcode ? ` - ${zipcode}` : ''}`}</p>}
                  </div>
                </div>
                <button onClick={Change} className="combinedaddressdivflexbuuton">Change</button>
              </div>
            </div>
            <div className="default-boxdiv">
            <input type="checkbox" name="default" checked={formValues.default} onChange={handleInputChange} className="form-check-input default-box "></input>
            <label className="Defaultaddresslabel">Default address</label>
            </div>
            <div className="errors errorsaddress">{error422}</div>
          </div>
        </div>
        <button onClick={confirmAndProceed2} className="combinedaddressdivconfirmButtonprceed mt-2">Confirm & Proceed</button>
        </>
    )
}
export default WithToast(Updatesearchlocationstore);