import { country_codeapi,getuserbussinesslocationapi,getuserdeleteapi,getuserlocationapi,GoogleMapurl,locationstoreapi,locationupdateapi,modulesproviderapi } from "./urlhelper";
import axiosInstance from "../utils/axiosutils";
import axios from "axios";
const googlemapkey = GoogleMapurl();
const countrycodeurl = country_codeapi();
const modulesproviderapiurl = modulesproviderapi();
const getuserbussinesslocationapiurl = getuserbussinesslocationapi();
const getuserlocationapiurl = getuserlocationapi();
const deletelocationurl = getuserdeleteapi();
const locationstoreapiurl = locationstoreapi()
const locationupdateapiurl = locationupdateapi();
export const mcc_list_ip = async() => {
   return await axiosInstance.get(countrycodeurl);
}
export const modules_provider = async() => {
   return await axiosInstance.get(modulesproviderapiurl);
}
export const bussiness_location = async() => {
   return await axiosInstance.get(getuserbussinesslocationapiurl);
}
export const user_location_get = async() => {
   return await axiosInstance.get(getuserlocationapiurl);
}
export const user_location_delete = async(data) => {
   return await axiosInstance.post(deletelocationurl,data);
}
export const user_location_store = async(data) => {
   return await axiosInstance.post(locationstoreapiurl,data);
}
export const user_location_update = async(data) => {
   return await axiosInstance.post(locationupdateapiurl,data)
}
export const fetchgeocode = async(latLng) => {
   return await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        latlng: `${latLng.lat},${latLng.lng}`,
        key: googlemapkey
      }
    })
}