import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement);

const ScreenTime = () => {
  const currentDay = new Date().getDay(); // Get the current day (0-6 for Sun-Sat)

  const data = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
      {
        data: [4, 5, 7, 3, 4.7, 5.12], // Example data
        backgroundColor: 'RGB(2, 201, 139)',
        barThickness: 20,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
      customLabels: {
        id: 'customLabels',
        afterDraw(chart) {
          const { ctx, scales } = chart;
          const xAxis = scales.x;
          const yAxis = scales.y;

          const drawRoundedRect = (ctx, x, y, width, height, radius) => {
            ctx.beginPath();
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            ctx.lineTo(x + radius, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
            ctx.closePath();
          };

          xAxis.ticks.forEach((tick, index) => {
            const isCurrentDay = index === currentDay;
            const xPos = xAxis.getPixelForTick(index);
            const yPos = yAxis.bottom + 20; // Position below the X-axis

            // Draw background for the current day's label
            if (isCurrentDay) {
              ctx.fillStyle = 'rgb(2, 201, 139)'; // Background color
              drawRoundedRect(ctx, xPos - 20, yPos - 13, 40, 20, 10); // Adjust dimensions and radius
              ctx.fill();
              ctx.fillStyle = 'white'; // Text color
            } else {
              ctx.fillStyle = 'black'; // Default text color
            }

            // Draw the label text
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(data.labels[index], xPos, yPos - 2);
          });
        },
      },
    },
    scales: {
      x: {
        grid: { display: false, drawBorder: false },
        ticks: { display: true }, // Hide default ticks to use custom labels
      },
      y: {
        position: 'right',
        grid: { drawBorder: true, color: 'black', },
        ticks: {
          stepSize: 2,
          color: 'black',
          font: { size: 12, weight: 'bold' },
        },
        beginAtZero: true,
        max: 8,
      },
    },
  };

  return (
    <div className='p-4 ms-0 rounded screenTime'>
      <div className='ms-0'>
        <h6 className='mt-2 pb-3'>Average On-screen time</h6>
        <strong>5h 12m</strong>
      </div>

      <div className='mt-4 screenBarchart'>
        <Bar data={data} options={options} plugins={[options.plugins.customLabels]} />
      </div>
    </div>
  );
};

export default ScreenTime;
