import React, { useState, useRef, useEffect, useCallback } from "react";
import {useNavigate } from "react-router-dom";
import back from '../images/arrow-left-01.png';
import file from "../images/job_details_icons/Document_green-01.png";
import fileopen from "../images/folder-open-01.png";
import plus1 from '../images/plus.png';
import eye from "../images/job_details_icons/eye_green-01.png";
import cancel from '../images/add_document/cancel-02.png';
import edit from '../images/job_details_icons/edit_1-01.png';
import transparent from '../images/Menu_icons/My_documents_black-01.png';
import Navbars from "../navbar";
import Modal from 'react-bootstrap/Modal';
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import closecircle from '../images/times-circle-01.png';
import documentorange from '../images/Add_document-01.png';
import popdeletes from '../images/general-id/delete-01.png';
import { Image } from "antd";
import Swal from 'sweetalert2';
import { formatDateForExpiry, formatDateForInput,getFormattedDate, formatFileSize, handleDragOver, handleDragOver1, handleDrop, handleDrop1,handleFileChange, handleEditfilechange,CustomSuffixIcon,disableFutureDates,disablePastDates, transformString,handleKeyDown,extractLoginUser,handleApiError,generateUniqueKey} from "../api/utils/utils";
import { vechicledocumentstoreapi, vechicledocumentgetapi, publicDomain, vechicledocumentupdateapi, vechicledocumentdeleteapi } from "../api/helper/urlhelper";
import axiosInstance from "../api/utils/axiosutils";
import SkeletonLoader from "./skeletonloader";
import WithToast from "../api/hooks/withtoasthook";
import { useParams } from "react-router-dom";
import PDFViewer from "./pdfviewer";
const workhistorydocumentstoreapiurl = vechicledocumentstoreapi();
const workhistorydocumentgetapiurl = vechicledocumentgetapi();
const publicUrl = publicDomain();
const workhistorydocumentupdateapiurl = vechicledocumentupdateapi();
const workhistorydocumentdeleteapiurl = vechicledocumentdeleteapi();
function Vechicledocument({showErrorToast}) {
  const navigate = useNavigate();
  const documentRef = useRef(null);
  const {userId} = useParams();
  const login_user = extractLoginUser();
  const [documents, setDocuments] = useState([]);
  const [fileType, setFileType] = useState('');
  const [originalDocName, setOriginalDocName] = useState('');
  const [isLoading1, setIsLoading1] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isLoading4,setIsLoading4] = useState(false);
  const [docname, setDocname] = useState('');
  const [dateofissue, setDateofissue] = useState('');
  const [expiryissue, setExpiryofissue] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [error422,setError422] = useState('');
  const [generaldocumentsave, setGeneraldocumentsave] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [format, setFormat] = useState('');
  const [editDocName, setEditDocName] = useState('');
  const [editDateOfIssue, setEditDateOfIssue] = useState('');
  const [editExpiryDate, setEditExpiryDate] = useState('');
  const [editinputValue, setEditInputValue] = useState('');
  const [editImagedetails, setEditImageDetails] = useState(false);
  const [modalStates, setModalStates] = useState({  modal1: false,modalEdit1: false,});
  const [uniqueKeys, setUniqueKeys] = useState({ uniqueKey: 0, uniqueKey1: 0 ,uniqueKey2: 0});
  const [editImage, setEditImage] = useState([]);
  const [editProgress, setEditProgress] = useState(100);
  const [editProgressColor, setEditProgressColor] = useState('#28a745');
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [ischange, setIschange] = useState(false);
  const fileInputRef1 = useRef(null);
  const [user_document_id, setUser_Document_id] = useState('');
  const [isPreviewVisible1, setPreviewVisible1] = useState(false);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [isPreviewVisible2, setPreviewVisible2] = useState(false);
  const [expiryerror,setExpiryerror] = useState({
    expirydateerror : '',editexpirydateerror : '',editimageerror : '',imageerror:''
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open) => {
    setIsOpen(open);
  }; 
  const handlePDFViewerClose = (modalId) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalId]: true,
    }));
  };
  const handleExpiryClick = (e) => {
    if (!dateofissue) {
      setExpiryerror((prevState) => ({
        ...prevState,
        expirydateerror:'Please select Date of Issue first'
      }));
      setIsOpen(false);
    } else {
      setExpiryerror((prevState) => ({
        ...prevState,
        expirydateerror:''
      }));
      setIsOpen(true);
    }
  };
  const handleEditExpiryClick = (e) => {
    if (!editDateOfIssue) {
      setExpiryerror((prevState) => ({
        ...prevState,
        editexpirydateerror:'Please select Date of Issue first'
      }));
      setIsOpen(false);
    } else {
      setExpiryerror((prevState) => ({
        ...prevState,
        editexpirydateerror:''
      }));
      setIsOpen(true);
    }
  };
  // let id = localStorage.getItem('userId');
  const workhistorygetdocument = useCallback(async() => {
    try {
      setIsLoading1(true);
      const response = await axiosInstance.get(`${workhistorydocumentgetapiurl}?user_vehicle_id=${userId}`);
      if (response.status === 200) {
        setIsLoading1(false);
        const doc = response.data.data.values
        setDocuments(doc);
      }
    } catch (error) {
      if(error.response){
        if(error.response.status === 422){
          const error_code = error.response.data.error.user_vehicle_id;
          if(error_code){
          navigate(`/u/${login_user}/documents`);
          }else{
            handleApiError(error, setIsLoading1, null, true, showErrorToast);
          }
        }else{
          handleApiError(error, setIsLoading1, null, true, showErrorToast);
        }
      }else{
        handleApiError(error, setIsLoading1, null, true, showErrorToast);
      }
    }
  },[showErrorToast,userId]);

  useEffect(() => {
    workhistorygetdocument()
  }, []);
  const initialState = {
    inputValue: '',
    docname: '',
    dateofissue: '',
    expiryissue: '',
    uploading: false,
  };
  const backs = () => {
    navigate(`/u/${login_user}/documents`);
  }
  const handleClose1 = (modalName) => {
    setModalStates((prevState)=>({
      ...prevState,[modalName] : false
    }))
    setProgress(100);
    setInputValue(initialState.inputValue);
    setDocname(initialState.docname);
    setDateofissue(initialState.dateofissue);
    setExpiryofissue(initialState.expiryissue);
    setUploading(initialState.uploading);
    setImage([]);
    setError422('');
    setExpiryerror((prevState) => ({
      ...prevState,
      expirydateerror:''
    }))
    setExpiryerror((prevState) => ({
      ...prevState,
      imageerror:''
    }))
  }
  const handledelete = () => {
    setUploading(false);
    setProgress(0);
    setImage([]);
    setDocname('');
  }
  const popuplodcancel = () => {
    handleClose1('modal1');
    setProgress(100);
  }
  const close1 = () => {
    handleClose1('modal1');
    setProgress(100);
  }
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressColor, setProgressColor] = useState("#28a745");
  const [image, setImage] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const addgeneral = (modalName) => {
    setModalStates((prevState)=>({
      ...prevState,['modal1'] : false
    }))
    setModalStates((prevState)=>({
      ...prevState,[modalName] : true
    }))
  }
  const handleEyeClickimage = (doc) => {
    const las = `${publicUrl}/core/${doc.user_vehicle_document_map.length > 0
          ? doc.user_vehicle_document_map[doc.user_vehicle_document_map.length - 1].path
          : doc.user_vehicle_document_map.length > 0
            ? doc.user_vehicle_document_map[doc.user_vehicle_document_map.length - 1].path
            : ''
        }`
    if(las.endsWith('.pdf')){
          setSelectedDocument(doc);
          const newUniqueKey = generateUniqueKey('model');
          setUniqueKeys((prevState)=>({
            ...prevState,uniqueKey2 : newUniqueKey
          }))
        }
        else{
          setSelectedDocument(doc);
          setPreviewVisible2(true);
        }
  };
  const fileInputRef = useRef(null);
  const handleFileClick = () => {
    fileInputRef.current.click();
    setError422('');
    setExpiryerror((prevState)=>({
      ...prevState,imageerror:''
    }));
  }
  const handleEditError = (message) => {
      setExpiryerror((prevState)=>({
        ... prevState,
        editimageerror : message
      }))
  }
  const handleError = (message) => {
    setExpiryerror((prevState)=>({
      ... prevState,
      imageerror : message
    }))
  }
  const handleFileDrop = (selectedFile) => {
    handleFileChange(
      { target: { files: [selectedFile] } },
      setImage,
      setFileSize,
      setFileType,
      setProgress,
      setProgressColor,
      setUploading,
      setFormat,
      setDocname
    );
  };
  const handleeditFileDrop = (selectedFile1) => {
    handleEditfilechange(
      { target: { files: [selectedFile1] } },
      setEditImage,
      setFileSize,
      setFileType,
      setEditProgress,
      setEditProgressColor,
      setEditImageDetails,
      setFormat,
      setEditDocName,
    );
  }
  const parseFileExtension = (fileName) => {
    const dotIndex = fileName.lastIndexOf('.');
    return dotIndex !== -1 ? fileName.slice(dotIndex) : '';
  };
  const handleEyeClick = (image,modalId) => {
    if(image[0] && typeof image[0] === 'string' && image[0].startsWith('data:application/pdf')){
      setSelectedImage(image[0]);
      const newUniqueKey = generateUniqueKey(modalId);
      setUniqueKeys((prevState)=>({
       ...prevState, uniqueKey: newUniqueKey,
      }));
    setModalStates((prevState) => ({
      ...prevState,
      [modalId]: false,
    }));
    }else{
      setSelectedImage(image[0]);
      setModalStates((prevState) => ({
        ...prevState,
        [modalId]: false,
      }));
      setPreviewVisible(true);
    }
  };
  const checkFormvechicleValidity = () => {
    const isValid = (
      inputValue.trim() !== '' &&
      docname.trim() !== '' &&
      dateofissue !== '' &&
      dateofissue !== null &&
      image.length > 0
    );
    setGeneraldocumentsave(isValid);
  };
  useEffect(() => {
    checkFormvechicleValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, docname, dateofissue, image]);
  const generalsave = async () => {
    try {      
      let imagestring = image.toString();
      const vechicleimages = {
        data: imagestring,
        document_name: fileType
      };
      let data = {
        user_vehicle_id: userId,
        vehicle_document_type: inputValue,
        document_name: docname,
        issued_at: formatDateForInput(dateofissue),
        images: [vechicleimages],
      }
      if(expiryissue){
        data.expired_at = formatDateForExpiry(expiryissue);
      }
      setIsLoading4(true);
      const response = await axiosInstance.post(workhistorydocumentstoreapiurl, data,);
      if (response.status === 200) {
        handleClose1('modal1');
        await workhistorygetdocument();
        setIsLoading4(false);
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const handledeletedocument = async (doc) => {
    try {
      const user_document_id1 = doc.id;
      const user_vehicle_document_map = doc.user_vehicle_document_map;
      const data = {
        user_vehicle_document_id : user_document_id1,
        user_vehicle_document_map_id : user_vehicle_document_map[0].id 
      }
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        setIsLoading4(true);
        const response = await axiosInstance.post(`${workhistorydocumentdeleteapiurl}`,data,);
        if (response.status === 200) {
          await workhistorygetdocument();
          setIsLoading4(false);
        }
      }
    }
    catch (error) {
      handleApiError(error, setIsLoading4, null, true, showErrorToast);
    }
  }
  const [user_vechicle_id, setUser_vechicle_id] = useState('');
  const handelgeneraledit = async (doc) => {
    try {
      setModalStates((prevState)=>({
        ...prevState,['modal1'] : false
      }))
      setModalStates((prevState)=>({
        ...prevState,['modalEdit1'] : true
      }))
      if (doc) {
        setOriginalDocName(doc.document_name || '');
        setUser_vechicle_id(doc.user_vehicle_id);
        setEditDocName((prevEditDocName) => doc.document_name || prevEditDocName);
        setEditInputValue(doc.vehicle_document_country.name);
        const formattedIssuedDate = doc.issued_at.split(' ');
        setEditDateOfIssue(dayjs(formattedIssuedDate[0],'YYYY-MM-DD'));
        if(doc.expired_at){
        const formattedExpiredDate = doc.expired_at.split(' ');
        setEditExpiryDate(dayjs(formattedExpiredDate[0],'YYYY-MM-DD'));
        }else{
          setEditExpiryDate(null)
        }
        setUser_Document_id(doc.id);
        if (doc.user_vehicle_document_map?.length > 0) {
          const lastDocument = doc.user_vehicle_document_map[
            doc.user_vehicle_document_map.length - 1
          ];
          const lastDocumentPath = lastDocument.path;
          const imageUrl = `${publicUrl}/core/${lastDocumentPath}`;
          setEditImage([imageUrl]);
          const fileSize = formatFileSize(lastDocument.fileSize);
          setFileSize(fileSize);
          setFormat(parseFileExtension(lastDocument.path));
        }
      }
    } catch (error) {
      console.error("Error in handelgeneraledit:", error);
    }
  }
  const handleEyeClick1 = (editImage, modalName) => {
    if (editImage[0] && typeof editImage[0] === 'string' && (editImage[0].endsWith('.pdf') || editImage[0].startsWith('data:application/pdf'))) {
      setSelectedImage1(editImage[0]);
      const newUniqueKey = generateUniqueKey(modalName);
      setUniqueKeys((prevState)=>({
        ...prevState, uniqueKey1: newUniqueKey,
       }));
      setModalStates((prevState) => ({
        ...prevState,
        [modalName]: false,
      }));
    } else {
      setSelectedImage1(editImage[0]);
      setModalStates((prevState) => ({
        ...prevState,
        [modalName]: false,
      }));
      setPreviewVisible1(true);
    }
  };
  const handleCloseedit1 = (modalName) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
    setError422('');
    setEditImageDetails(false);
    setEditProgress(100);
    setExpiryerror((prevState) => ({
      ...prevState,
      editexpirydateerror:''
    }))
    setIschange(false);
  }
  const closeedit1 = () => {
   handleCloseedit1('modalEdit1');
  }
  const handleFileimageClick1 = () => {
    fileInputRef1.current.click();
    setExpiryerror((prevState)=>({
      ...prevState,editimageerror:''
    }))
  }
  const handledelete1 = () => {
    setEditImage([]);
    setProgress(0);
    setFileSize(0);
    setEditImageDetails(true);
    setIschange(true);
    setEditDocName('');
  }
  const generalupdatecancel = () => {
    handleCloseedit1('modalEdit1');
  }
  const checkgeneralupdateValidity = () => {
    const isValid = (
      editinputValue.trim() !== '' &&
      editDocName.trim() !== '' &&
      editDateOfIssue !== '' &&
      // editExpiryDate !== '' &&
      editDateOfIssue !== null &&
      // editExpiryDate !== null &&
      editImage.length > 0
    );
    setGeneraldocumentsave(isValid);
  };
  useEffect(() => {
    checkgeneralupdateValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editinputValue, editDocName, editDateOfIssue, editImage]);
  const generalupdatesave = async () => {
    try {
      let data;
      let imagestring = editImage.toString();
      let vechicleimages = {
        data: imagestring,
        document_name: fileType
      };
      if (ischange) {
        data = {
          user_vehicle_document_country_id: user_document_id,
          user_vehicle_id: user_vechicle_id,
          vehicle_document_type: editinputValue,
          ...(editDocName !== originalDocName && { document_name: editDocName }),
          expired_at: formatDateForExpiry(editExpiryDate),
          issued_at: formatDateForInput(editDateOfIssue),
          images: [vechicleimages],
        }
      }
      else {
        data = {
          user_vehicle_document_country_id: user_document_id,
          user_vehicle_id: user_vechicle_id,
          vehicle_document_type: editinputValue,
          ...(editDocName !== originalDocName && { document_name: editDocName }),
          expired_at: formatDateForExpiry(editExpiryDate),
          issued_at: formatDateForInput(editDateOfIssue),
        }
      }
      setIsLoading4(true);
      const response = await axiosInstance.post(workhistorydocumentupdateapiurl, data);
      if (response.status === 200) {
        handleCloseedit1('modalEdit1')
        await workhistorygetdocument();
        setIsLoading4(false);
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const acceptPDF = true;
  return (
    <>
      {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )}
      <Navbars />
      <div className="container-fluid">
        <div className="workhistorydocs">
          <div className="document1" ref={documentRef}>
            <div className="docnav1">
              <img src={back} onClick={backs} className="me-3" alt="arrow" width='19.5px' height="15.5px" style={{ cursor: 'pointer' }}></img>
              <p className="workhistdocp">Documents</p>
            </div>
            {documents.length > 0 ? (
              <>
              <div className="divshow1400">
               {documents.map((doc, index) => (
                <div key={index} className="docbox1">
                  <div className="d-flex justify-content-between align-items-center" style={{gap:'10px'}}>
                  <div className="d-flex align-items-center" style={{width:'28%' ,overflow:'hidden'}}>
                    <div>
                      <img src={file} alt="file" width='60px' height='auto' className="me-2" />
                    </div>
                    <div style={{width:'100%',overflow:'hidden',}}> 
                      <p className="docviewp truncated-text">Document Type</p>
                      <p className="docviewp2 truncated-text">{doc.vehicle_document_country.name}</p>
                    </div>
                    </div>
                    <div style={{width:'23%',overflow:'hidden'}}>
                      <p className="docviewp truncated-text">Document name</p>
                      <p className="docviewp2 truncated-text">{doc.document_name}</p>
                    </div>
                    <div style={{width:'15%',overflow:'hidden'}}>
                      <p className="docviewp truncated-text">Date of Issue</p>
                      <p className="docviewp2 truncated-text">{getFormattedDate(doc.issued_at)}</p>
                    </div>
                    <div style={{width:'15%',overflow:'hidden'}}>
                      <p className="docviewp truncated-text">Expire Date</p>
                      <p className="docviewp2 truncated-text">{doc.expired_at ? getFormattedDate(doc.expired_at) : '\u00A0'}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-end" style={{width:'19%'}}>
                    <div className="me-3">
                      <button className="docedit" onClick={() => handelgeneraledit(doc)}>
                        <img className="me-3" src={edit} alt="edit" width='18px' height='auto' />
                        Edit
                      </button>
                    </div>
                    <div className="d-flex flex-column">
                      <img className="mb-2 documenteyeview" src={eye} onClick={() => handleEyeClickimage(doc)} alt="eye" />
                      <img className="documenteyeview" src={cancel} onClick={() => handledeletedocument(doc)} alt="file" width='29.89px' height='auto' />
                    </div>
                  </div>
                  </div>
                </div>
              ))}
              </div>
              <div className="divshow1401">
      {documents.map((doc, index) => (
    <div key={index} className="docbox1">
      <div className="d-flex justify-content-between align-items-center" style={{gap:'10px'}}>
        <div className="d-flex align-items-center divshow140130" style={{overflow:'hidden'}}>
          <img src={file} alt="file" width="60px" className="me-3" height="auto" />
          <div style={{width:'100%', overflow:'hidden'}}>
            <p className="docviewp truncated-text">Document Type</p>
            <p className="docviewp2 truncated-text">
              {doc.vehicle_document_country.name}
            </p>
          </div>
        </div>
        <div className="divshow140130"style={{overflow:'hidden'}} >
          <p className="docviewp truncated-text">Document name</p>
          <p className="docviewp2 truncated-text">
            {doc.document_name}
          </p>
        </div>
        <div className="divshow140120" style={{overflow:'hidden'}}>
          <p className="docviewp truncated-text">Date of Issue</p>
          <p className="docviewp2 truncated-text">
            {getFormattedDate(doc.issued_at)}
          </p>
        </div>
        <div className="divshow140120" style={{overflow:'hidden'}}>
          <p className="docviewp truncated-text">Expire Date</p>
          <p className="docviewp2 truncated-text">
            {doc.expired_at ? getFormattedDate(doc.expired_at) : '\u00A0'}
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-end divshow140150end" style={{width:'20%'}}>
        <div className="divshow140150" >
          <button className="docedit me-3" onClick={() =>handelgeneraledit(doc)}>
            <img className="me-3" src={edit} alt="edit" width="18px" height="auto"/>
            Edit
          </button>
          </div>
          <div className="d-flex flex-column justify-content-end align-items-end divshow140150" >
            <img
              className="mb-2 documenteyeview"
              src={eye}
              onClick={() =>  handleEyeClickimage(doc)}
              alt="eye"
            />
            <img
              className="documenteyeview"
              src={cancel}
              onClick={() =>  handledeletedocument(doc)}
              alt="file"
              width="29.89px"
              height="auto"
            />
          </div>
        </div>
      </div>
    </div>
  ))}
</div>
              </>
            ) : (
              <div className="d-flex mt-5 justify-content-center align-items-center flex-column generalnew ">
                {isLoading1 ? (
                   <>
                   <SkeletonLoader documentRef={documentRef}/>
                 </>
                ) : (
                  <>
                    <img src={documentorange} alt="document" className="docorangeimage"></img>
                    <h6 className="addnewgeneralh6 mt-2">New Document</h6>
                    <p className="generalp generalp1">Securely upload and organize all your essential vehicle documents—licenses, registration papers, insurance, and more—at your fingertips</p>
                    <button className="addnewgeneralbutton mt-2" onClick={()=>addgeneral('modal1')}>Add Document</button>
                  </>
                )}
              </div>
            )}
            {documents.length > 0 && (
              <div className="text-end historywidth mt-3">
                <button className="workadddocumentbutton" onClick={()=>addgeneral('modal1')}><img
              src={plus1}
              className="me-2"
              alt="plus"
              width="15px"
              height="auto"
            ></img>New</button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal show={modalStates.modal1} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={()=>handleClose1('modal1')} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Upload Document</h6>
            <img src={closecircle} onClick={close1} alt="close" className="docpopclose"></img>
          </div>
          <div className="error-message1">{error422}</div>
          <div>
            {uploading ? (
              <div className="popprogressbox">
                <div className="d-flex align-items-center" style={{gap:'10px'}}>
                  <div className="d-flex align-items-center mb-2" style={{width:'80%'}}>
                  {image[0] && typeof image[0] === 'string' && image[0].startsWith('data:application/pdf')  ? (
                       <img src={transparent} alt="file" className="popuploadfileimg me-3"></img>
                    ):(
                      <img src={image} alt="file" className="popuploadfileimg me-3"></img>
                    )}
                    <div style={{overflow:'hidden'}}>
                      <p className="popfilefrontp">{docname ? `${transformString(fileType)}.${transformString(format)}` : `${transformString(fileType)}.${transformString(format)}`}</p>
                      <p className="popfilekb">{formatFileSize(fileSize)}</p>
                    </div>
                  </div>
                  <div style={{width:'20%',textAlign:'end'}}>
                    {progress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleEyeClick(image,'modal1')} className="popupeye me-3"></img>
                    )}
                    <img src={popdeletes} alt="delete" className="popupeye" onClick={() => handledelete()}></img>
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            ) : (
              <div className="popinputbox mt-3" onClick={handleFileClick} onDrop={(e) => handleDrop(e, handleFileDrop,handleError,acceptPDF)} onDragOver={handleDragOver}>
                <div className="d-flex align-items-center justify-content-center flex-column p-4">
                  <img src={fileopen} className="popuploadflle" alt="file" width='60px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".jpg, .jpeg, .png, .pdf" onChange={(e) => handleFileChange(e, setImage, setFileSize, setFileType, setProgress, setProgressColor, setUploading, setFormat, setDocname)} />
              </div>
            )}
            {expiryerror.imageerror && <div className="error-message">{expiryerror.imageerror}</div>}
            <div className="row docpoprow">
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Document Type<span className="docpopstar">*</span></label>
                <input type="text" className={`form-control docform ${inputValue ? 'docform-active' : ''}`} value={inputValue} onChange={(e) => {setInputValue(e.target.value);setError422('')}} placeholder="Enter Document Type" />
              </div>
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Document Name<span className="docpopstar">*</span></label>
                <input className={`form-control docform ${docname ? 'docform-active' : ''}`} name="docname" value={docname}  readOnly={image.length === 0} onChange={(e) => { setDocname(e.target.value); setFileType(e.target.value);setError422(''); }} placeholder="Enter Document Name"></input>
              </div>
            </div>
            <div className="row docpoprow">
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Date of Issue<span className="docpopstar">*</span></label>
                <DatePicker value={dateofissue}  disabledDate={disableFutureDates} onChange={(date) => {setDateofissue(date);setError422('');setExpiryofissue('');setExpiryerror((prevState) => ({...prevState,expirydateerror:''}))}} picker="month" placeholder="MM/YY" className={`${dateofissue ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: dateofissue ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={dateofissue}/>}  onKeyDown={handleKeyDown}/>
                {expiryerror.expirydateerror && <div className="error-message">{expiryerror.expirydateerror}</div>} 
              </div>
              <div className="col-sm-6 col-12">
                <label className="docpoplabel" >Expiry Date</label>
                <DatePicker value={expiryissue} onClick={handleExpiryClick}  open={isOpen}  onOpenChange={handleOpenChange} disabledDate={disablePastDates(dateofissue)} onChange={(date) =>{ setExpiryofissue(date);setError422('')}} picker="month" placeholder="MM/YY" className={`${expiryissue ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: expiryissue ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={expiryissue}/>}  onKeyDown={handleKeyDown}/>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
              <button className="docpopcancel me-3" onClick={popuplodcancel}>Cancel</button>
              <button className="docpopsave" style={{ backgroundColor: generaldocumentsave ? '#5D64F2' : '#EAEAEA', color: generaldocumentsave ? '#FFF' : '#333', cursor: generaldocumentsave ? 'pointer' : 'not-allowed' }} disabled={!generaldocumentsave} onClick={generalsave}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={modalStates.modalEdit1} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={()=>handleCloseedit1('modalEdit1')} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Upload Document</h6>
            <img src={closecircle} onClick={closeedit1} alt="close" className="docpopclose"></img>
          </div>
          <div className="error-message1">{error422}</div>
          <div>
            {editImagedetails ? (
              <div className="popinputbox mt-3" onClick={handleFileimageClick1} onDrop={(e) => handleDrop1(e, handleeditFileDrop,handleEditError,acceptPDF)} onDragOver={handleDragOver1}>
                <div className="d-flex align-items-center justify-content-center flex-column p-4">
                  <img src={fileopen} className="popuploadflle" alt="file" width="60px" height="auto" />
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size: 20 MB)</p>
                </div>
                <input type="file" ref={fileInputRef1} style={{ display: 'none' }} accept=".jpg, .jpeg, .png, .pdf" onChange={(e) => handleEditfilechange(e, setEditImage, setFileSize, setFileType, setEditProgress, setEditProgressColor, setEditImageDetails, setFormat, setEditDocName)} />
              </div>
            ) : (
              <div className="popprogressbox">
                <div className="d-flex align-items-center " style={{gap:'10px'}}>
                  <div className="d-flex align-items-center mb-2" style={{width:'80%'}}>
                  {editImage[0] && typeof editImage[0] === 'string' && (editImage[0].endsWith('.pdf') || editImage[0].startsWith('data:application/pdf')) ? (
                     <img src={transparent} alt="file" className="popuploadfileimg me-3" />
                  ) : (
                    <img src={editImage} alt="file" className="popuploadfileimg me-3" />
                  )}
                    <div style={{overflow:'hidden'}}>
                      <p className="popfilefrontp">{editDocName ? `${transformString(editDocName)}.${transformString(format)}` : `${transformString(fileType)}.${transformString(format)}`}</p>
                      {fileSize && fileSize >= 1024 && (
                        <p className="popfilekb">{formatFileSize(fileSize)}</p>
                      )}
                    </div>
                  </div>
                  <div style={{width:'20%',textAlign:'end'}}>
                    {editProgress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleEyeClick1(editImage,'modalEdit1')} className="popupeye me-3" />
                    )}
                    <img src={popdeletes} alt="delete" onClick={() => handledelete1()} className="popupeye" />
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${editProgress}%`, backgroundColor: editProgressColor }} aria-valuenow={editProgress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            )}
            {expiryerror.editimageerror && <div className="error-message">{expiryerror.editimageerror}</div>}
            <div className="row docpoprow">
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Document Type<span className="docpopstar">*</span></label>
                <input type="text" className={`form-control docform ${editinputValue ? 'docform-active' : ''}`} value={editinputValue} onChange={(e) => {setEditInputValue(e.target.value);setError422('')}} placeholder="Enter custom value" />
              </div>
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Document Name<span className="docpopstar">*</span></label>
                <input className={`form-control docform ${editDocName ? 'docform-active' : ''}`} name="editDocName" value={editDocName}  readOnly={editImage.length === 0} onChange={(e) => { setEditDocName(e.target.value); setFileType(e.target.value);setError422('') }}></input>
              </div>
            </div>
            <div className="row docpoprow">
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Date of Issue<span className="docpopstar">*</span></label>
                <DatePicker value={editDateOfIssue}  disabledDate={disableFutureDates} onChange={(date) => {setEditDateOfIssue(date);setError422('');setEditExpiryDate('')}} picker="month" placeholder="MM/YY" className={`${editDateOfIssue ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: editDateOfIssue ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={editDateOfIssue}/>}  onKeyDown={handleKeyDown}/>
                {expiryerror.editexpirydateerror && <div className="error-message">{expiryerror.editexpirydateerror}</div>}
              </div>
              <div className="col-sm-6 col-12">
                <label className="docpoplabel" >Expiry Date</label>
                <DatePicker value={editExpiryDate} onClick={handleEditExpiryClick}  open={isOpen}  onOpenChange={handleOpenChange}  disabledDate={disablePastDates(editDateOfIssue)} onChange={(date) => {setEditExpiryDate(date);setError422('')}} picker="month" placeholder="MM/YY" className={`${editExpiryDate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: editExpiryDate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={editExpiryDate}/>}  onKeyDown={handleKeyDown}/>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
              <button className="docpopcancel me-3" onClick={generalupdatecancel}>Cancel</button>
              <button className="docpopsave" style={{ backgroundColor: generaldocumentsave ? '#5D64F2' : '#EAEAEA', color: generaldocumentsave ? '#FFF' : '#333', cursor: generaldocumentsave ? 'pointer' : 'not-allowed' }} disabled={!generaldocumentsave} onClick={generalupdatesave}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {selectedDocument && (
        <>
          {(() => {
            const las = `${publicUrl}/core/${selectedDocument.user_vehicle_document_map.length > 0
              ? selectedDocument.user_vehicle_document_map[selectedDocument.user_vehicle_document_map.length - 1].path
              : ''
            }`;
            const isPDF = las.endsWith('.pdf');
            return isPDF ? (
              <PDFViewer
                key={uniqueKeys.uniqueKey2}
                fileUrl={las}
              />
            ) : (
              <Image
                src={las}
                alt="Enlarged Image"
                preview={{
                  visible: isPreviewVisible2,
                  onVisibleChange: setPreviewVisible2,
                  onClose: () => { setPreviewVisible2(false); },
                }}
                style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999', display: 'none' }}
              />
            );
          })()}
        </>
      )}

   
{selectedImage && typeof selectedImage === 'string' && selectedImage.startsWith('data:application/pdf') ? (
      // <embed src={selectedImage} type="application/pdf" width="100%" height="100%" />
      <PDFViewer key={uniqueKeys.uniqueKey} fileUrl={selectedImage} onClose={() => handlePDFViewerClose('modal1')}/>
      ) : (
      <Image
      src={selectedImage}
      alt="Enlarged Image"
      preview={{
        visible: isPreviewVisible,
        onVisibleChange: setPreviewVisible,
        onClose: () => {
          setPreviewVisible(false);
          setModalStates((prevState)=>({
            ...prevState,['modal1'] : true,
          }))
          // setShow1(true); // Show another modal if needed
        },
      }}
      style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999',display: 'none' }}
      />
      )}
    
    {selectedImage1 && (
         typeof selectedImage1 === 'string' && (selectedImage1.endsWith('.pdf') || selectedImage1.startsWith('data:application/pdf') )? (
          <PDFViewer key={uniqueKeys.uniqueKey1} fileUrl={selectedImage1} onClose={() => handlePDFViewerClose('modalEdit1')}/>
        ) : (
        <Image src={selectedImage1} alt="Enlarged Image" preview={{ visible: isPreviewVisible1, onVisibleChange: setPreviewVisible1,onClose: () => {setPreviewVisible1(false);setModalStates((prevState)=>({...prevState,['modalEdit1'] : true})); },}}style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999', display: 'none' }}/>
        )
      )}

    </>
  );
}
export default WithToast(Vechicledocument);