import React, { useState, useEffect, useRef,useCallback } from "react";
import back from '../../../images/arrow-left-01.png';
import locationcurrent from '../../../images/address-icons/Group 1488.png';
import Navbars from "../../../navbar";
import { useIpContext } from "../../../api/hooks/iphook";
import PlaceAutocomplete from "../../addresssection/autocomplete";
import { useNavigate } from "react-router-dom";
import SearchedLocation from "./seachedlocation";
import SearchedLocationconfirm from "./searchedlocationconfirm";
import Searchedlocationstore from "./searchedlocationstore";
import { useCountryData } from "../../../api/hooks/mobilehook";
import Mapdesign from "../newaddress/mapdesign";
import { fetchAddress } from "../../addresscommon/addressfunction";
const INITIAL_CAMERA = {
  center: { lat: 0, lng: 0 },
  zoom: 10,
};
function AddAddress({login_user}) {
  const { getUserIpCountryCode,latitude,longitude} = useIpContext();
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryByIp,empty_values
  } = useCountryData();
  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);
  const [loading, setLoading] = useState(false);
  const fetchLocation = useCallback(async () => {
    setLoading(true);
      await getUserIpCountryCode().finally(()=>setLoading(false));
  }, [getUserIpCountryCode]);

    useEffect(() => {
      if (latitude && longitude) {
        setCameraProps({
          center: { lat: latitude, lng: longitude },
          zoom: 10
        });
      } else {
        fetchLocation(); 
      }
    }, [latitude, longitude, fetchLocation]);
    
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [mobileChange, setMobileChange] = useState('');
  const [markerPosition, setMarkerPosition] = useState(null);
  const [locationState, setLocationState] = useState({ searchLocation: true, searchedLocation: false,searchedLocationconfirm :false,searchedLocationstore:false });
  const [addressComponents, setAddressComponents] = useState({
    street: "",
    country: "",
    city: "",
    zipcode: "",
    state: "",
    latitude: "",
    longitude: ""
  });
  const [formValues, setFormValues] = useState({
    Name:'',
    mcc_id:'',
    houseNumber: '',
    apartment: '',
    addressLine2: '',
    addressType: '',
    otherType: '',
    default:false,
    city:'',
    postcode:'',
    fetchcondition:false,
  });
  
  const emptynull = () => {
    setMarkerPosition(null);
    setSelectedPlace(null)
    setAddressComponents((prevstate)=>({
      ...prevstate,street:'', country: "",city: "",zipcode: "",state: "",latitude: "",longitude: "",code:''
    }));

  }
  const countryData = {
    mccId,
    dialCode,
    countryCode,
    countryOptions,
    mobile_length,
    mobile_starting_digits,
    isLoading,
    loadCountryByIp,
    setMccId,
    setDialCode,
    setCountryCode,
    setMobile_length,
    setMobile_starting_digits,
    empty_values
  };
  const [selectedPlace, setSelectedPlace] = useState(null);
  const handleMarkerClick = (event) => {
    if (event) {
      const clickedLatLng = {
        lat: event.detail.latLng.lat,
        lng: event.detail.latLng.lng
      };
      setCameraProps({ center: clickedLatLng, zoom: 15 });
      setMarkerPosition(clickedLatLng);
      fetchAddress(clickedLatLng,setAddressComponents,setLocationState,setCameraProps,setMarkerPosition);
    }
  };
  
  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setCameraProps({ center: currentLatLng, zoom: 15 });
        setMarkerPosition(currentLatLng);
         fetchAddress(currentLatLng,setAddressComponents,setLocationState,setCameraProps,setMarkerPosition);
        // fetchAddress(currentLatLng);
      }, (error) => {
        console.error("Error getting current location:", error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };


  const showstate = (show) => {
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation:'searchedLocation' === show,
      searchLocation:'searchLocation' === show,
      searchedLocationconfirm: 'searchedLocationconfirm' === show,
      searchedLocationstore: 'searchedLocationstore' === show,
    }));
  }
  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    const newlatlang = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    };
    setCameraProps({ center:place.geometry.location, zoom: 15 });
    setMarkerPosition(place.geometry.location);
    fetchAddress(newlatlang,setAddressComponents,setLocationState,);
  };
  console.log(addressComponents);
  
  const handleCameraChange = (ev) => {
    setCameraProps(ev.detail);
  };
  const confirmAndProceed = (show) => {
    showstate(show);
  };
  const confirmAndProceed1 = (show) => {
    showstate(show)
    setError('');
  };
  const back1 = async(show) => {
    emptynull();
    if (latitude && longitude) {
      setCameraProps({
        center: { lat: latitude, lng: longitude },
        zoom: 10
      });
    } else {
      fetchLocation(); 
    }
    showstate(show);
  }
  const back2 = (show) => {
    showstate(show);
  }
  const back3 = (show) => {
    showstate(show)
  }
  const Change = () =>{
    showstate('searchLocation');
    if (latitude && longitude) {
      setCameraProps({
        center: { lat: latitude, lng: longitude },
        zoom: 10
      });
    } else {
      fetchLocation(); 
    }
    setMarkerPosition(null);
  }
  const backprofile = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const [divHeight, setDivHeight] = useState(window.innerHeight - 160); // Initial height minus navbar height

  useEffect(() => {
    const updateDivHeight = () => {
      setDivHeight(window.innerHeight - 160); // Subtract navbar height
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateDivHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);
  return (
    <>
      <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars/>
        </div>
        <div className="address_div">
          <div className="address_div1" style={{height:divHeight}}>
          <div className="address_divhalf1">
            {locationState.searchLocation && (
              <>
                <div className="d-flex align-items-center">
                  <img src={back} onClick={backprofile} className="me-2" style={{cursor:'pointer'}} alt="back" width="17px" height="14px" />
                  <h6 className="addaddressh6">Add Address</h6>
                </div>
                <div>
                  <p className="addaddressSearchp">Search Location</p>
                  <button onClick={handleUseCurrentLocation} className="addaddresscurrentbutton">
                    <img src={locationcurrent} className="locationcurrent" alt="upload" />
                    Use my current location
                  </button>
                  <PlaceAutocomplete onPlaceSelect={handlePlaceSelect} />
                </div>
               </> 
               )}
               {locationState.searchedLocation && (
              <SearchedLocation addressComponents={addressComponents} confirmAndProceed={confirmAndProceed} back1={back1} Change={Change}/>
               )}
              {locationState.searchedLocationconfirm && (
              <SearchedLocationconfirm
                addressComponents={addressComponents}
                back2={back2}
                Change={Change}
                confirmAndProceed1={confirmAndProceed1}
                formValues={formValues}
                setFormValues={setFormValues}
                error={error}
                setError={setError}
                countryData={countryData}
                setMobileChange={setMobileChange}
                mobileChange={mobileChange}
              />
              )}
              {locationState.searchedLocationstore && (
              <Searchedlocationstore
              addressComponents={addressComponents}
                back3={back3}
                Change={Change}
                formValues={formValues}
                setFormValues={setFormValues}
                countryData={countryData}
                mobileChange={mobileChange}
                login_user={login_user}
              />
              )}
            </div>
              <div className="address_divhalf2">
              {loading ? (
                    <div className="skeleton-loader1"></div>
                  ) : (
                    <Mapdesign handleMarkerClick={locationState.searchLocation ? handleMarkerClick : ''} selectedPlace={selectedPlace}  markerPosition={markerPosition} cameraProps={cameraProps} handleCameraChange={handleCameraChange}/>
                  )}
            </div>
          </div>
          </div>
        </div>
    </>
  );
}

export default AddAddress;
