import React, { useState } from "react";
import Sidebar from "./sidebar";
import Navbars from "./navbar";
import './review.css';
import './parental.css';
import parentalimg from './images/parental_control.png';
import cancel from './images/cancel-01.png';
import minoruser1 from "./images/minor_user1.png";
import { useNavigate } from "react-router-dom";
import { extractLoginUser } from "./api/utils/utils";

function Parental() {
  const loginUser = extractLoginUser();
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [step, setStep] = useState(1);  // To manage next steps

  const countryCodes = [
    { code: '+1', name: 'USA' },
    { code: '+44', name: 'UK' },
    { code: '+91', name: 'Ind' },
    { code: '+61', name: 'Aus' },
  ];

  // Open the modal when button is clicked
  const handleButtonClick = () => {
    setShowModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setPhoneNumber('');
    setEmail('');
  };

  const handleClickClose = ()=>{
    setStep(1);
    setPhoneNumber('');
    setName('');
    setGender('');
    setDob('');
  }

  // Handle input change for email and phone number
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
    if (name === 'phoneNumber') setPhoneNumber(value);
  };

  // Move to the next step after form submission
  const handleNextStep = () => {
    if (email && phoneNumber) {
      setStep(2);  // Move to the next step
      handleCloseModal();  // Close the modal after moving to the next step
    } else {
      alert('Please fill out both fields.');
    }
  };
  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [formData, setFormData] = useState(null);

  const nav = useNavigate();

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setFormData({
  //     name,
  //     gender,
  //     dob,
  //   });
  // };

  
    return (
        <>
            <Navbars />
            <div className="container-fluid bg-light" style={{padding:'0px'}}>
                <div className="documentrow">
                    <div className="col-12 col-md-3 col-lg-3  documentside">
                        <Sidebar />
                    </div>

                    <div>
      {/* Button that triggers the modal */}
      {/* <button onClick={handleButtonClick}>Show Email & Phone Number Form</button> */}

      {/* Conditional rendering of Modal */}
      {showModal && (
        <div className="modal addchildmodal">
          <div className="modal-content">
            <h4>Add Child <a onClick={handleCloseModal}><img src={cancel} alt="cancel" width='16px' height='16px'></img></a></h4>
            <form>
            <div className="text-align-left">
              
                <label>Phone Number</label>
                <div className="d-flex justify-content-between">
                <select id="country" value={countryCode} onChange={handleCountryCodeChange}>
        {countryCodes.map((country, index) => (
          <option key={index} value={country.code}>
            {country.name} ({country.code})
          </option>
        ))}
      </select> 
                <input
                  type="tel"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleInputChange}
                  placeholder="Enter your phone number"
                />
              </div>
              </div>
              <div className="line-container">
                <hr className="left-line"/>
                <span className="or-text">or</span>
                <hr className="right-line"/>
            </div>
              <div>
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                />
              </div>
              
              <button type="button" onClick={handleNextStep}>Next</button>
            </form>
            
          </div>
        </div>
      )}

      {/* Displaying the next step content */}
      {step === 2 && (
         <div className="modal addchildmodal">
          <div className="modal-content">
        <div>
          {/* <h2>Next Step</h2>
          <p>Email: {email}</p>
          <p>Phone Number: {phoneNumber}</p> */}
{/* <form onSubmit={handleSubmit}> */}
<form>
<h4>Add Child <a onClick={handleClickClose}><img src={cancel} alt="cancel" width='16px' height='16px'></img></a></h4>
<div className="text-align-left">
                <label>Phone Number</label>
                <div className="d-flex justify-content-between">
                <select id="country" value={countryCode} onChange={handleCountryCodeChange}>
                  {countryCodes.map((country, index) => (
                    <option key={index} value={country.code}>
                      {country.name} ({country.code})
                    </option>
                  ))}
                </select>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleInputChange}
                  placeholder="Enter your phone number"
                />
              </div>
              </div>
              <hr></hr>
  <div><img src={minoruser1}></img></div>
  
        <div>
          <label htmlFor="name">Name:</label>
          <input
            placeholder="Enter Your Name"
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="gender">Gender:</label>
          <select
            id="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div>
          <label htmlFor="dob">Date of Birth:</label>
          <input
            type="date"
            id="dob"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required
          />
        </div>

        <button class="modaladdminor mt-3 mb-5" type="submit" onClick={()=>{nav(`/u/${loginUser}/addparental`)}}>Add Minor</button>
      </form>

      {/* {formData && (
        <div>
          <h2>Submitted Data:</h2>
          <p><strong>Name:</strong> {formData.name}</p>
          <p><strong>Gender:</strong> {formData.gender}</p>
          <p><strong>Date of Birth:</strong> {formData.dob}</p>
        </div>
      )} */}
        </div>
        </div></div>
      )}
    </div>
                    <div className="col-12  col-md-9 col-lg-9  documentmain">
                        <div className="parentalmain">
                            <div className="parentalmainfirst">
                            <h5 className="heading">Parental Control</h5>
                            <p>Manage your General Profile, including Personal Details, Services, Contact info and Addresses</p>
                            </div>
                            <div className="parnetalmainlast">
                                <div className="d-flex justify-content-between">
                            <h5 className="heading">Minor List</h5>
                            <button className="addminor-btn me-4" onClick={handleButtonClick}>Add Minor</button>
                            </div>
                            <div className="parentalimg"><img src={parentalimg} className="" alt="parental" width='' height='' /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Parental;