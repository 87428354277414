import React, { useEffect, useState } from 'react'
import "react-circular-progressbar"
import { Modal, Form } from 'react-bootstrap'
import 'react-circular-progressbar/dist/styles.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import eye from '../images/eye.png'
import clock from '../images/clock.svg'
import plus from '../images/add.png'

const Actions = () => {

  // FOR MODAL 
  const [, setFullScreen] = useState(true);
  const [show, setShow] = useState(false);

  const handlePopup = (breakpoint) => {
    setFullScreen(breakpoint);
    setShow(true);
  }

  // RADIO BUTTON
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // ON DEMAND PASSWORD
  const [showPassword, setShowPassword] = useState(false);
  
  // ON DEMAND TIME SETTINGS
  const [timeLimit, setTimeLimit] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  
  const handleTimeChange = (e, field) => {
    setTimeLimit({
      ...timeLimit,
      [field]: parseInt(e.target.value),
    });
  };

  // ON DEMAND PASSWORD PATTERN
  const [password, setPassword] = useState('');

  const handleChange = (event) => {
    const newChar = event.target.value.slice(-1);
    if (event.target.value.length > password.length) {
      setPassword(password + newChar);
    } else {
      setPassword(password.slice(0, -1));
    }
  };

  // REPEAT DISPLAY SCREEN TIME  
  const [time1, setTime1] = useState('');
  const [time2, setTime2] = useState('');
  const [totalDisplayTime, setTotalDisplayTime] = useState('')

  const calculateTimeDifference = (start, end) =>{
    if (!start || !end) return ''

    const parsedTime = (time) => {
      const [hours, minutes] = time.split(/[: ]/).map(Number)
      const isPM = time.includes('PM')
      return{hours: isPM ? (hours % 12) + 12 : hours % 12, minutes} 
    }

    const startParsed = parsedTime(start)
    const endParsed = parsedTime(end)

    const startMinutes = startParsed.hours * 60 + startParsed.minutes
    const endMinutes = endParsed.hours * 60 + endParsed.minutes

    let diffMinutes = endMinutes - startMinutes
    if (diffMinutes < 0) diffMinutes += 24 * 60

    const hours = Math.floor(diffMinutes / 60)
    const minutes = diffMinutes % 60

    return `${hours}h ${minutes}m`;
  }

  useEffect(() => {
    const totalTime = calculateTimeDifference(time1, time2);
    setTotalDisplayTime(totalTime);
  }, [time1, time2]);


  // SETTING START TIME

  const handleMeridiemChangeFirst = (event) => {
    const selectedTime1 = event.target.value;

    const hours = parseInt(selectedTime1.substring(0, 2))
    const minutes = selectedTime1.substring(3, 5)
    const period = hours >= 12 ? 'PM' : 'AM'
    const formattedTime1 = (hours % 12 || 12) + ':' + minutes + ' ' + period

    setTime1(formattedTime1)
  }

  // SETTING END TIME

  const handleMeridiemChangeLast = (event) => {
    const selectedTime2 = event.target.value;

    const hours = parseInt(selectedTime2.substring(0, 2))
    const minutes = selectedTime2.substring(3, 5)
    const period = hours >= 12 ? 'PM' : 'AM'
    const formattedTime2 = (hours % 12 || 12) + ':' + minutes + ' ' + period

    setTime2(formattedTime2)
  }

  return (
    <div className='mainAction'>
      <div className='pt-2 pb-1 actionTitle'>
        <span className='ps-3 h6'>Actions</span><br />
      </div>

      <div className='mt-3 mb-4 justify-content-center align-items-center action_buttons'>
          <button className="button bg-primary alert">Alert</button>
          <br />
          <button className="button warning">Hide</button>
          <br />
          <button className="button lock" onClick={() => handlePopup()}>
            Lock
          </button>

        {/*LOCK OPTION POPUP*/}

        <Modal className='lockPopup'
          show={show}
          fullScreen={false}
          onHide={() => {
            setShow(false); // Close the modal
            setSelectedOption(null); // Reset the selected option
          }}>
          <Modal.Header closeButton className="pt-3 pb-2 px-4 ps-5 lockOptionsHeader" >
            <h5>Lock Options</h5>
          </Modal.Header>
          <Modal.Body className="px-0 ps-0 mx-2 ">
          <div className="row-cols-1 row-cols-lg-2 row popUpMain">
            <div className='col lockOptions'>
              <div className='optionButtons'>
                <div className="d-flex justify-content-between mb-3 py-2 px-2 align-items-center logOut">
                  <span>LogOut Instead</span>
                  <input
                    type="radio"
                    name="lockOption"
                    value="LogOut"
                    onChange={handleOptionChange}
                    style={{cursor: 'pointer'}}
                  />
                </div>

                <div className="d-flex justify-content-between mb-3 py-2 px-2 align-items-center nextDay">
                  <span>Until Next Day</span>
                  <input
                    type="radio"
                    name="lockOption"
                    value="NextDay"
                    onChange={handleOptionChange}
                    style={{cursor: 'pointer'}}
                  />
                </div>    

                <div className="d-flex justify-content-between mb-3 py-2 px-2 align-items-center repeat">
                  <span>Repeat</span>
                  <input
                    type="radio"
                    name="lockOption"
                    value="Repeat"
                    onChange={handleOptionChange}
                    style={{cursor: 'pointer'}}
                  />
                </div>

                <div className="d-flex justify-content-between mb-3 py-2 px-2 align-items-center onDemand">
                  <span>On Demand</span>
                  <input
                    type="radio"
                    name="lockOption"
                    value="OnDemand"
                    onChange={handleOptionChange}
                    style={{cursor: 'pointer'}}
                  />
                </div>
              </div>
            
              {/*LOGOUT DATA*/}
              {selectedOption === 'LogOut' &&(
                <div></div>    
              )}              
            </div>
              
              <div className="col">
                <div className='d-flex'>  
                <div className='d-none d-lg-block ms-0 me-0 pe-0 vr vertical-line' />

              {/*NEXTDAY DATA*/}

                {selectedOption === 'NextDay' && (
                  <div className="d-flex justify-content-center align-items-center ms-5 my-4 nextDayContent">
                    <CircularProgressbar
                      value={50}
                      text={'12:20:32'} 
                      styles={buildStyles({
                        textSize: '15px',
                        textColor: 'black',   
                        pathColor: 'orange',  
                        trailColor: 'lightgrey'
                      })}/>
                  </div>
                )}

              {/*REPEAT DATA*/}

                  {selectedOption === 'Repeat' && (
                    <div className='justify-content-start repeatColumn'>
                      <div className="d-flex justify-content-between mt-2 mx-0 form-check form-switch custom-switch form-check sunday">
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">SUNDAY</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />  
                      </div>

                      <div className="d-flex justify-content-between mt-2 mx-0 form-check form-switch custom-switch form-check sunday">
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">MONDAY</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />  
                      </div>

                      <div className="d-flex justify-content-between mt-2 mx-0 form-check form-switch custom-switch form-check sunday">
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">TUESDAY</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />  
                      </div>

                      <div className="d-flex justify-content-between mt-2 mx-0 form-check form-switch custom-switch form-check sunday">
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">WEDNESDAY</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />  
                      </div>

                      <div className="d-flex justify-content-between mt-2 mx-0 form-check form-switch custom-switch form-check sunday">
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">THURSDAY</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />  
                      </div>

                      <div className="d-flex justify-content-between mt-2 mx-0 form-check form-switch custom-switch form-check sunday">
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">FRIDAY</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />  
                      </div>

                      <div className="d-flex justify-content-between mt-2 mx-0 form-check form-switch custom-switch form-check sunday">
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">SATURDAY</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />  
                      </div>
                    </div>
                  )}

                  {selectedOption === 'OnDemand' &&(
                    <div className='onDemandContent'>
                      <Form className=''>
                        <Form.Group className='ms-2' style={{fontSize: '14px'}}>
                          <Form.Label>Passcode</Form.Label>
                          <div className='form-group'>
                          <input className='form-control' type="text" id="password" value={showPassword ? password : password.replace(/./g, '*')} onChange={handleChange} style={{fontWeight: 'bolder',fontSize: '20px'}}/>
                            <img src={eye} style={{right: '5px'}} onClick= {() => setShowPassword(!showPassword)} alt=''/>
                          </div>
                        </Form.Group>
                      </Form>

                      <Form className='my-3 mx-2'>
                        <div className='row timeLimit'>
                        <span className='my-1'>Time Limit</span><br />
                          <div className='col sm-4'>
                            <Form.Label>Days</Form.Label>
                              <Form.Select value={timeLimit.days} onChange={(e) => handleTimeChange(e, 'days')}>
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={19}>19</option>
                                <option value={20}>20</option>
                                <option value={21}>21</option>
                                <option value={22}>22</option>
                                <option value={23}>23</option>
                                <option value={24}>24</option>
                                <option value={25}>25</option>
                                <option value={26}>26</option>
                                <option value={27}>27</option>
                                <option value={28}>28</option>
                                <option value={29}>29</option>
                                <option value={30}>30</option>
                                <option value={31}>31</option>
                            </Form.Select>
                          </div>

                          <div className='col-sm-4'>
                          <Form.Label>Hours</Form.Label>
                              <Form.Select value={timeLimit.hours} onChange={(e) => handleTimeChange(e, 'hours')}>
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={19}>19</option>
                                <option value={20}>20</option>
                                <option value={21}>21</option>
                                <option value={22}>22</option>
                                <option value={23}>23</option>
                              </Form.Select>
                          </div>

                          <div className='col-sm-4'> 
                          <Form.Label>Minutes</Form.Label>
                              <Form.Select value={timeLimit.minutes} onChange={(e) => handleTimeChange(e, 'minutes')}>
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={19}>19</option>
                                <option value={20}>20</option>
                                <option value={21}>21</option>
                                <option value={22}>22</option>
                                <option value={23}>23</option>
                                <option value={24}>24</option>
                                <option value={25}>25</option>
                                <option value={26}>26</option>
                                <option value={27}>27</option>
                                <option value={28}>28</option>
                                <option value={29}>29</option>
                                <option value={30}>30</option>
                                <option value={31}>31</option>
                                <option value={32}>32</option>
                                <option value={33}>33</option>
                                <option value={34}>34</option>
                                <option value={35}>35</option>
                                <option value={36}>36</option>
                                <option value={37}>37</option>
                                <option value={38}>38</option>
                                <option value={39}>39</option>
                                <option value={40}>40</option>
                                <option value={41}>41</option>
                                <option value={42}>42</option>
                                <option value={43}>43</option>
                                <option value={44}>44</option>
                                <option value={45}>45</option>
                                <option value={46}>46</option>
                                <option value={47}>47</option>
                                <option value={48}>48</option>
                                <option value={49}>49</option>
                                <option value={50}>50</option>
                                <option value={51}>51</option>
                                <option value={52}>52</option>
                                <option value={53}>53</option>
                                <option value={54}>54</option>
                                <option value={55}>55</option>
                                <option value={56}>56</option>
                                <option value={57}>57</option>
                                <option value={58}>58</option>
                                <option value={59}>59</option>
                              </Form.Select>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}

                </div>
              </div>
          </div>

          <div className='row popUpSecond'>
                
                {selectedOption === 'Repeat'  &&(
                  <div className='repeatRow'>
                    <div>
                      <hr />
                      <div>
                        <h6>Total Daily Screen Time</h6>
                        <div className= 'd-flex my-2 align-items-center justify-content-between totalTime'>
                          <label className="ms-2">{totalDisplayTime || '0h 0m'}</label>
                          <img src={clock} className='me-2' alt='' />
                        </div>
                      </div>
                    </div>
                  
                    <div className='d-flex mt-4 timeStat'>
                      <div className='startTimeDiv'>
                        <h6>Start Time</h6>
                        <div className= 'd-flex my-2 align-items-center justify-content-between startTime'>
                        <p className='position-absolute ms-2'>{time1}</p>
                        <input className='position-absolute right-0' type="time" value={time1} onChange={handleMeridiemChangeFirst} />       
                        </div>
                      </div>

                      <div className='timeDividor'>
                        <h6 className=''>to</h6>
                      </div>

                      <div className='endTimeDiv'>
                        <h6>End Time</h6>
                        <div className= 'd-flex my-2 align-items-center justify-content-between endTime'>
                        <p className='position-absolute ms-2 '>{time2}</p>
                        <input className='position-absolute right-0' type="time" value={time2} onChange={handleMeridiemChangeLast} />
                        </div>
                      </div>

                      <div>
                        <img src={plus} className='' style={{marginTop: '18px'}} alt='' />
                      </div>
                    </div>
                    <hr />
                    <button id='submit' className='px-4 py-1'>Submit</button>
                  </div>
                )}

                {selectedOption === 'NextDay'  &&(
                  <div className='same'>
                    <hr />
                    <button id='submit' className='px-4 py-1'>Submit</button>
                  </div>
                )}

                {selectedOption === 'OnDemand'  &&(
                  <div className='same'>
                    <hr />
                    <button id='submit' className='px-4 py-1'>Submit</button>
                  </div>
                )}

                
          </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default Actions