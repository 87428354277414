import React from "react";

const Heading = () => {
    return(
        <div className="ms-3 p-5 bg-white title">
            <p className="dash_title">EVzone Marketplace</p>
        </div>
    )
}

export default Heading