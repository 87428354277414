import React, { useState } from "react";
import Sidebar from "./sidebar";
import Navbars from "./navbar";
import './review.css';
import './parental.css';
import { useNavigate } from "react-router-dom";
import { extractLoginUser } from "./api/utils/utils";
import cancel from './images/cancel-01.png';
import minoruser1 from "./images/minor_user1.png";
import minoruser2 from "./images/minor_user2.png";
import minoruser3 from "./images/minor_user3.png";

function Addparental() {

    const navigate = useNavigate();
    const login_user = extractLoginUser();
    const reviews = () => {
        navigate(`/u/${login_user}/reviewratings`);
    }

const [showModal, setShowModal] = useState(false);
const [email, setEmail] = useState('');
const [countryCode, setCountryCode] = useState('+1');
const [phoneNumber, setPhoneNumber] = useState('');
const [step, setStep] = useState(1);

const [name, setName] = useState('');
const [gender, setGender] = useState('');
const [dob, setDob] = useState('');

const nav = useNavigate();

const countryCodes = [
    { code: '+1', name: 'USA' },
    { code: '+44', name: 'UK' },
    { code: '+91', name: 'Ind' },
    { code: '+61', name: 'Aus'},
    // Add more countries here
  ];

const handleButtonClick = () => {
    setShowModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNextStep = () => {
    if (email && phoneNumber) {
      setStep(2);  // Move to the next step
      handleCloseModal();  // Close the modal after moving to the next step
    } else {
      alert('Please fill out both fields.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
    if (name === 'phoneNumber') setPhoneNumber(value);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

    return (
        <>
            <Navbars />
            <div className="container-fluid bg-light" style={{padding:'0px'}}>
                <div className="documentrow">
                    <div className="col-12 col-md-3 col-lg-3  documentside">
                        <Sidebar />
                    </div>
                    <div className="col-12  col-md-9 col-lg-9  documentmain">
                        <div className="parentalmain">
                            <div className="parentalmainfirst">
                            <h5 className="heading">Parental Control</h5>
                            <p>Manage your General Profile, including Personal Details, Services, Contact info and Addresses</p>
                            </div>
                            
                            <div className="parnetalmainlast addparental-sec">
                                <div className="d-flex justify-content-between">
                            <h5 className="heading">Minor List</h5>
                            <button className="addminor-btn me-4" onClick={handleButtonClick}>Add Minor</button>
                            </div>
                            <div className="row addparentalboxrow mt-4 ">
                                <div className="col-12 addparentalboxbottom  col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container addparentalbox border p-0">
                                        <div class="d-flex addparental-head"><img
                                            src={minoruser1}
                                            alt="school"
                                            className="img-fluid  d-md-block addparentalpageimg"
                                        /><h6>Wade Warren</h6></div>
                                        <div className="addparentalbody">
                                            <p>Age<span>15</span></p>
                                            <p>Gender<span>Male</span></p>
                                            <p>Email<span>wadewarren43@gmail.com</span></p>
                                            <p>Phone Number<span>+256 8968656326</span></p>
                                    
                                        <div className="d-flex align-items-center mb-0 justify-content-around py-2 gap-1">
                                        <button className="view-btn mb-0" onClick={()=>{nav('/u/:user/parentelControl')}}>View</button>
                                        <button className="release-btn mb-0">Release</button>
                                            <button className="remove-btn mb-0">Remove</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 addparentalboxbottom  col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container addparentalbox border p-0">
                                        <div class="d-flex addparental-head"><img
                                            src={minoruser3}
                                            alt="school"
                                            className="img-fluid  d-md-block addparentalpageimg"
                                        /><h6>Esther Howard</h6></div>
                                        <div className="addparentalbody">
                                            <p>Age<span>15</span></p>
                                            <p>Gender<span>Male</span></p>
                                            <p>Email<span>wadewarren43@gmail.com</span></p>
                                            <p>Phone Number<span>+256 8968656326</span></p>
                                    
                                        <div className="d-flex align-items-center mb-0 justify-content-around py-2
                                        gap-1">
                                        <button className="view-btn mb-0" onClick={()=>{nav('/u/:user/parentelControl')}}>View</button>
                                        <button className="release-btn mb-0">Release</button>
                                            <button className="remove-btn mb-0">Remove</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 addparentalboxbottom  col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container addparentalbox border p-0">
                                        <div class="d-flex addparental-head"><img
                                            src={minoruser2}
                                            alt="school"
                                            className="img-fluid  d-md-block addparentalpageimg"
                                        /><h6>Jacob Jones</h6></div>
                                        <div className="addparentalbody">
                                            <p>Age<span>15</span></p>
                                            <p>Gender<span>Male</span></p>
                                            <p>Email<span>wadewarren43@gmail.com</span></p>
                                            <p>Phone Number<span>+256 8968656326</span></p>
                                    
                                        <div className="d-flex align-items-center mb-0 justify-content-around py-2
                                        gap-1">
                                        <button className="view-btn mb-0" onClick={()=>{nav('/u/:user/parentelControl')}}>View</button>
                                        <button className="release-btn mb-0">Release</button>
                                            <button className="remove-btn mb-0">Remove</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal addchildmodal">
                <div className="modal-content">
                    <h4>Add Child <a onClick={handleCloseModal}><img src={cancel} alt="cancel" width='16px' height='16px'></img></a></h4>
                    <form>
                    <div className="text-align-left">
                    
                        <label>Phone Number</label>
                        <div className="d-flex justify-content-between">
                        <select id="country" value={countryCode} onChange={handleCountryCodeChange}>
                {countryCodes.map((country, index) => (
                <option key={index} value={country.code}>
                    {country.name} ({country.code})
                </option>
                ))}
            </select> 
                        <input
                        type="tel"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleInputChange}
                        placeholder="Enter your phone number"
                        />
                    </div>
                    </div>
        <hr></hr>
                    <div>
                        <label>Email Address</label>
                        <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        placeholder="Enter your email"
                        />
                    </div>
                    
                    <button type="button" onClick={handleNextStep}>Next</button>
                    </form>
                    
                </div>
                </div>
            )}

            {step === 2 && (
                <div className="modal addchildmodal">
                    <div className="modal-content">
                        <div>
                            <form>
                                <h4>Add Child <a onClick={handleCloseModal}><img src={cancel} alt="cancel" width='16px' height='16px'></img></a></h4>
                                <div className="text-align-left">
                                    <label>Phone Number</label>
                                    <div className="d-flex justify-content-between">
                                        <select id="country" value={countryCode} onChange={handleCountryCodeChange}>
                                            {countryCodes.map((country, index) => (
                                                <option key={index} value={country.code}>
                                                    {country.name} ({country.code})
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                        type="tel"
                                        name="phoneNumber"
                                        value={phoneNumber}
                                        onChange={handleInputChange}
                                        placeholder="Enter your phone number"
                                        />
                                    </div>
                                </div>
                                <hr></hr>
                                <div><img src={minoruser1}></img></div>
                                    <div>
                                        <label htmlFor="name">Name:</label>
                                        <input
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="gender">Gender:</label>
                                        <select
                                            id="gender"
                                            value={gender}
                                            onChange={(e) => setGender(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="dob">Date of Birth:</label>
                                        <input
                                            type="date"
                                            id="dob"
                                            value={dob}
                                            onChange={(e) => setDob(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button class="modaladdminor mt-3 mb-5" type="submit" onClick={()=>{nav('/u/:user/addparental')}}>Add Minor</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default Addparental;