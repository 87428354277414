import React from 'react';
import {Pagination} from 'react-bootstrap';

const Purchases = () => {
  return (
        <div className='container-fluid'>
            <div className='row'>
              <div className="col">
                <h6 className='mt-4'>In-App Purchases</h6>
                <div className="mt-2 bg-white rounded-lg overflow-auto">
                  <table responsive className='table purchaseTable'>
                    
                    <thead class="table-light">
                      <tr>
                        <th>Order No</th>
                        <th>Product</th>
                        <th>Qty</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    
                    <tbody>
                      
                      <tr>
                        <td >#ORD123545</td>
                        <td >Ace Hardwares</td>
                        <td >10</td>
                        <td >1600</td>
                        <td >Processing</td>
                      </tr> 
                      
                      <tr>
                        <td>#ORD123532</td>
                        <td >Kaladi Traders</td>
                        <td >8</td>
                        <td >1600</td>
                        <td>Cancelled</td>
                      </tr> 
                      
                      <tr>
                        <td>#ORD123425</td>
                        <td >Bismi Home Appliances</td>
                        <td >5</td>
                        <td >2000</td>
                        <td >Completed</td>
                      </tr> 
                      
                      <tr>
                        <td>#ORD123425</td>
                        <td>Kalladi Traders</td>
                        <td >12</td>
                        <td >10000</td>
                        <td >Cancelled</td>
                      </tr> 
                      
                      <tr>
                        <td>#ORD123425</td>
                        <td >Kalladi Traders</td>
                        <td >12</td>
                        <td >3500</td>
                        <td >Completed</td>
                      </tr>

                    </tbody>
                  
                  </table>
                  
                  <div className="pagination pagination-no-border mt-0 mb-0">
                    <Pagination.Prev className='left'/>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>/</Pagination.Item>
                    <Pagination.Item>{20}</Pagination.Item>
                    <Pagination.Next className='right' />
                  </div>
                  </div>
              </div>
            </div>
        </div>
  );
};

export default Purchases;
