// import { mcc_list_ip } from "./apihandle";
// import { useIpContext } from "./ipContext";
// import { handleApiError } from "./commonapi";

// const useCountryData = (showErrorToast) => {
//   const { ipCountryCode, getUserIpCountryCode } = useIpContext();

//   const fetchCountryData = async (setMccId, setDialCode, setCountryCode,setCountryOptions,countryCode) => {
//     try {
//       const res = await mcc_list_ip();
//       const mcc_list = res.data.data;

//       const codes = mcc_list.map((mcc) => ({
//         country_code: mcc.country_code,
//         dial_code: mcc.code,
//         mcc_id: mcc.id,
//         label: `${mcc.name} (+${mcc.code})`,
//         value: mcc.country_code,
//         flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
//       }));
//       setCountryOptions(codes); 
//       const selectedCountryData = codes.find(
//         (country) => country.country_code === countryCode
//       );

//       if (selectedCountryData) {
//         setMccId(selectedCountryData.mcc_id);
//         setDialCode(`+${selectedCountryData.dial_code}`);
//         setCountryCode(selectedCountryData.country_code);
//       }
//     } catch (error) {
//       handleApiError(error, null, null, true, showErrorToast);
//     }
//   };


//   const loadCountryByIp = async (setMccId, setDialCode, setCountryCode,setCountryOptions) => {
//     await getUserIpCountryCode();
//     fetchCountryData(setMccId, setDialCode, setCountryCode,setCountryOptions,ipCountryCode);
//   };

//   const loadCountryBySelection = (setMccId, setDialCode, setCountryCode,setCountryOptions,selectedCountryCode) => {
//     fetchCountryData(setMccId, setDialCode, setCountryCode,setCountryOptions,selectedCountryCode);
//   };

//   return { loadCountryByIp, loadCountryBySelection };
// };

// export default useCountryData;

// CountryDataContext.js
import React, { createContext, useCallback, useContext, useState,useEffect } from 'react';
import { mcc_list_ip } from "../helper/apiurlhelper";
import { useIpContext } from "../hooks/iphook";
import { extractLengthValues, handleApiError } from "../utils/utils";
import WithToast from './withtoasthook';

const CountryDataContext = createContext();

export const useCountryData = () => {
  return useContext(CountryDataContext);
};
export const CountryDataProvider = WithToast(({ children, showErrorToast }) => {
  const { ipCountryCode, getUserIpCountryCode } = useIpContext();  
  const [mccId, setMccId] = useState(null);
  const [dialCode, setDialCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [shold, setShold] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [mobile_length,setMobile_length] = useState('');
  const [mobile_starting_digits,setMobile_starting_digits] = useState('');
  const [isLoading,setIsLoading] = useState(false);
  const fetchCountryData = useCallback(async(code) => {
    if(!code) return;
    try {
      setIsLoading(true);
      const res = await mcc_list_ip();
      const mcc_list = res.data.data;

      const codes = mcc_list.map((mcc) => ({
        country_code: mcc.country_code,
        dial_code: mcc.code,
        mcc_id: mcc.id,
        label: `${mcc.name} (+${mcc.code})`,
        value: mcc.country_code,
        flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
        mobile_length:mcc.mobile_length,
        mobile_starting_digits:mcc.mobile_starting_digits
      }));
      
      setCountryOptions(codes); 
      const value = code
      const selectedCountryData = codes.find((country) => country.country_code === value);
      if (selectedCountryData) {
        setMccId(selectedCountryData.mcc_id);
        setDialCode(`+${selectedCountryData.dial_code}`);
        setCountryCode(selectedCountryData.country_code);
        setMobile_length(selectedCountryData.mobile_length);
        setMobile_starting_digits(selectedCountryData.mobile_starting_digits);
        const maxLength = extractLengthValues(mobile_length);
      }
    } catch (error) {
      handleApiError(error, null, null, true, showErrorToast);
    }finally{
      setShold(false);
      setIsLoading(false);
    }
  },[showErrorToast,mobile_length,]);
  const updateCountryState = (country) => {
    if (country) {
      console.log(country);
      
      setMccId(country.mcc_id);
      setDialCode(`+${country.dial_code}`);
      setCountryCode(country.country_code);
      setMobile_length(country.mobile_length);
      setMobile_starting_digits(country.mobile_starting_digits);
      setShold(false);
    }
  };
  useEffect(()=>{

    if(shold && ipCountryCode && countryOptions.length > 0){
      console.log('stalin2');
      const selectedCountry = countryOptions.find((country) => country.country_code === ipCountryCode);
      if (selectedCountry) {
        updateCountryState(selectedCountry);
        setShold(false);
      }
    }
  },[shold,ipCountryCode,countryOptions])
  useEffect(() => {
    if (countryCode || mccId || dialCode || mobile_length || mobile_starting_digits) {
      console.log("State values updated:", {
        countryCode,
        mccId,
        dialCode,
        mobile_length,
        mobile_starting_digits,
      });
    }
  }, [countryCode, mccId, dialCode, mobile_length, mobile_starting_digits]);
  // Load country data based on IP country code
  useEffect(() => {
    if (shold && ipCountryCode && countryOptions.length === 0) {
      console.log('stalin3');
      fetchCountryData(ipCountryCode);
    }
  }, [shold, ipCountryCode, fetchCountryData, countryOptions.length]);

  // Auto-fetch IP country code if not available
  useEffect(() => {
    if (!ipCountryCode && shold) {
      console.log('stalin4');
      getUserIpCountryCode();
    }
  }, [ipCountryCode, getUserIpCountryCode,shold]);
  const loadCountryBySelection = (selectedCountryCode) => {
    console.log('stalin');
    
    if (countryOptions.length > 0 && selectedCountryCode) {
      const selectedCountryData = countryOptions.find(
        (country) => country.country_code === selectedCountryCode
      );
      if (selectedCountryData) {
        updateCountryState(selectedCountryData);
      }
    }else{
    fetchCountryData(selectedCountryCode);
    }
  };
  const empty_values = () => {
    setCountryCode('');
    setMccId('');
    setDialCode('');
    setMobile_length('');
    setMobile_starting_digits('');
  }
  const value = {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryByIp: (sholds = true) => setShold(sholds),loadCountryBySelection,empty_values
  }
  return (
    <CountryDataContext.Provider value={value}>
      {children}
    </CountryDataContext.Provider>
  );
});

