import React,{useEffect,useRef} from 'react';
import { APIProvider, Map, Marker, useMap, } from '@vis.gl/react-google-maps';
import { GoogleMapurl } from '../../../api/helper/urlhelper';
const googlemapkey = GoogleMapurl();
const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    }

    marker.setPosition(place.geometry?.location);
  }, [map, place, marker]);

  return null;
};
function Mapdesign({  handleMarkerClick, markerPosition, cameraProps, handleCameraChange, selectedPlace }) {
const markerRef = useRef(null);
  return (
    <>
      <APIProvider apiKey={googlemapkey} solutionChannel="GMP_devsite_samples_v3_rgmbasicmap">
        <Map
          {...cameraProps}
          onCameraChanged={handleCameraChange}
          gestureHandling="greedy"
          onClick={handleMarkerClick}
        >
          {markerPosition && (
            <Marker
              position={markerPosition}
              onClick={handleMarkerClick}
              ref={markerRef}
            />
          )}
        </Map>
        <MapHandler place={selectedPlace} marker={markerRef.current} />
      </APIProvider>
    </>
  );
}

export default Mapdesign;
