import React from 'react'
import './Main.css'
import Header from './Header';
import SideBar from './SideBar';
import Heading from './Heading';
import AvgDataUsage from './AvgDataUsage';
import Purchases from './Purchases';
import Transactions from './Transactions';
import ScreenTime from './ScreenTime';
import Actions from './Actions';
const Main = () => {

  return (
    <div className='conatiner-fluid Main p-0 m-0 overflow-hidden'>
      <Header />
      <div className='row me-0 pe-0' style={{marginTop:'5rem'}}>
        <div className='col bg-white d-none d-lg-block ms-0 me-0 pe-0 sideBar' style={{ maxWidth: '285px' }}>
          <SideBar />
        </div>
        
        <div className='col me-0 bg-light p-2 '>
         <Heading />
          <div className='container-fluid'>
            <div className='row'>
              <div className="col-xl-5 p-3">
              <div className="bg-white rounded overflow-hidden w-100 h-100 align-items-center">
              <AvgDataUsage />
              </div>
              </div>
              <div className="col-xl-7 py-3 ">
              <div className=" bg-white rounded d-flex h-100">
                    <Purchases />
                </div>
              </div>
            </div>
          </div>
          
          <div className='container-fluid'>
            <div className='row ps-1 pe-3'>
              
              <div className="col-xxl-5 my-2 transactionCol">
                <div className="rounded transactionDiv">
                  <Transactions />
                </div>
              </div>

              <div className="col-xxl-7 d-flex  px-4 align-items-start screen_actions">
                <div className="row row-cols-1 row-cols-md-2">
                  <div className="col d-flex screen_col" >
                    <div className='screen-time d-flex'>
                      <ScreenTime />  
                    </div>
                  </div>

                  <div className="col d-flex justify-content-center actionsCol">
                    <div className='actions rounded'>
                      <Actions />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main